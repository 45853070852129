export const stockImageUrls = [
  "/images/image (1).jpg",
  "/images/image (2).jpg",
  "/images/image (3).jpg",
  "/images/image (4).jpg",
  "/images/image (5).jpg",
  "/images/image (6).jpg",
  "/images/image (7).jpg",
  "/images/image (8).jpg",
  "/images/image (9).jpg",
  "/images/image (10).jpg",
  "/images/image (11).jpg",
  "/images/image (12).jpg",
  "/images/image (13).jpg",
  "/images/image (14).jpg",
  "/images/image (15).jpg",
  "/images/image (16).jpg",
  "/images/image (17).jpg",
  "/images/image (18).jpg",
  "/images/image (19).jpg",
  "/images/image (20).jpg",
  "/images/image (21).jpg",
  "/images/image (22).jpg",
  "/images/image (23).jpg",
  "/images/image (24).jpg",
  "/images/image (25).jpg",
  "/images/image (26).jpg",
  "/images/image (27).jpg",
  "/images/image (28).jpg",
  "/images/image (29).jpg",
  "/images/image (30).jpg",
  "/images/image (31).jpg",
  "/images/image (32).jpg",
  "/images/image (33).jpg",
  "/images/image (34).jpg",
  "/images/image (35).jpg",
  "/images/image (36).jpg",
  "/images/image (37).jpg",
  "/images/image (38).jpg",
  "/images/image (39).jpg",
  "/images/image (40).jpg",
  "/images/image (41).jpg",
  "/images/image (42).jpg",
  "/images/image (43).jpg",
  "/images/image (44).jpg",
  "/images/image (45).jpg",
  "/images/image (46).jpg",
  "/images/image (47).jpg",
  "/images/image (48).jpg",
  "/images/image (49).jpg",
  "/images/image (50).jpg",
  "/images/image (51).jpg",
  "/images/image (52).jpg",
  "/images/image (53).jpg",
  "/images/image (54).jpg",
  "/images/image (55).jpg",
  "/images/image (56).jpg",
  "/images/image (57).jpg",
  "/images/image (58).jpg",
  "/images/image (59).jpg",
  "/images/image (60).jpg",
  "/images/image (61).jpg",
  "/images/image (62).jpg",
  "/images/image (63).jpg",
  "/images/image (64).jpg",
  "/images/image (65).jpg",
  "/images/image (66).jpg",
  "/images/image (67).jpg",
  "/images/image (68).jpg",
  "/images/image (69).jpg",
  "/images/image (70).jpg",
  "/images/image (71).jpg",
  "/images/image (72).jpg",
  "/images/image (73).jpg",
  "/images/image (74).jpg",
  "/images/image (75).jpg",
  "/images/image (76).jpg",
  "/images/image (77).jpg",
  "/images/image (78).jpg",
  "/images/image (79).jpg",
  "/images/image (80).jpg",
  "/images/image (81).jpg",
  "/images/image (82).jpg",
  "/images/image (83).jpg",
];

