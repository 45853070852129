import { useParams } from "react-router-dom";
import styles from "./Payment.module.css";
import { getPaymentInfoAPI, paymentInitiateAPI } from "../../api/paymentApis";
import { useEffect, useState } from "react";
import { PaymentElement } from "@stripe/react-stripe-js";
import PaymentButton from "../../components/PaymentButton";
import { Button, Col, InputNumber, Row, Slider } from "antd";
import { useRestrictionStore } from "../../store/restrictions";
import { calculatePercentage } from "../../utils";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useAuth0 } from "@auth0/auth0-react";
import {
  updateAppointmentFeePerSessionAPI,
} from "../../api/appointmentApis";

// Load Stripe with your publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const appearance = {
  theme: "stripe", // You can use 'stripe', 'night', 'flat', 'none', or customize
  variables: {
    colorPrimary: "#0570de",
    colorBackground: "#ffffff",
    colorText: "#30313d",
    colorDanger: "#df1b41",
    // Customize more variables here
  },
  rules: {
    ".Input": {
      borderRadius: "4px",
    },
    ".Label": {
      color: "#30313d",
    },
    // Customize more rules here
  },
};

const Payment = () => {
  let { paymentId } = useParams();
  const { getIdTokenClaims } = useAuth0();

  const restrictionsData = useRestrictionStore(
    (state) => state.restrictionsData
  );

  const [clientSecret, setClientSecret] = useState("");
  const [showSlidingPriceScale, setShowSlidingPriceScale] = useState(false);
  const [slidingMin, setSlidingMin] = useState();
  const [slidingMax, setSlidingMax] = useState();
  const [paymentItem, setPaymentItem] = useState();
  const [paymentCurrency, setPaymentCurrency] = useState();

  const [inputValue, setInputValue] = useState(1);
  const onSlidingInputChange = (newValue) => {
    setInputValue(newValue);
  };

  const updateAppointmentFeePerSession = async (data) => {
    try {
      const token = await getIdTokenClaims();
      await updateAppointmentFeePerSessionAPI(token.__raw, {
        feepersession: data,
        paymentId: paymentId,
      });
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  const makePaymentHandler = async (paymentItems) => {
    // backend api call here
    const response = await paymentInitiateAPI({
      data: paymentItems,
      paymentId: paymentId,
    });

    setShowSlidingPriceScale(false);

    setClientSecret(response.data.client_secret);
  };

  const getPaymentInfoAPIHelper = async () => {
    try {
      const paymentData = await getPaymentInfoAPI(paymentId);

      if (
        !paymentData?.data?.data?.items[0]?.price &&
        paymentData?.data?.data?.items[0]?.slidingscalemax &&
        paymentData?.data?.data?.items[0]?.slidingscalemin
      ) {
        setShowSlidingPriceScale(true);
        setSlidingMin(paymentData?.data?.data?.items[0]?.slidingscalemin);
        setSlidingMax(paymentData?.data?.data?.items[0]?.slidingscalemax);
        setInputValue(paymentData?.data?.data?.items[0]?.slidingscalemin);
        setPaymentCurrency(paymentData?.data?.data?.items[0]?.currency);
        setPaymentItem(paymentData?.data?.data?.items[0]);
      } else if (
        paymentData?.data?.data?.items[0]?.price &&
        !paymentData?.data?.data?.items[0]?.slidingscalemax &&
        !paymentData?.data?.data?.items[0]?.slidingscalemin
      ) {
        makePaymentHandler(paymentData?.data?.data?.items);
      }
    } catch (error) {
      console.log("🚀 ~ getPaymentAPIHelper ~ error:", error);
    }
  };

  useEffect(() => {
    if (paymentId) {
      getPaymentInfoAPIHelper();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentId]);

  return (
    <>
      {showSlidingPriceScale ? (
        <div className={styles.slidingScaleBox}>
          <h1>Select price that you want to pay </h1>
          <Row>
            <Col span={16}>
              <Slider
                min={slidingMin}
                max={slidingMax}
                onChange={onSlidingInputChange}
                value={typeof inputValue === "number" ? inputValue : 0}
              />
            </Col>
            <Col span={1}>
              <InputNumber
                min={slidingMin}
                max={slidingMax}
                style={{
                  margin: "0 16px",
                }}
                value={inputValue}
                onChange={onSlidingInputChange}
              />
            </Col>
          </Row>

          <div className={styles.space}></div>

          {Number(
            restrictionsData?.find(
              (i) =>
                i.restrictionName === "Payment-Fee" && i.status === "active"
            )?.value
          ) ? (
            <div className={styles.paymentSectionItemDiv}>
              <p>Processing Fee</p>
              <p className={styles.feeAndCurrency}>
                {Number(
                  calculatePercentage(
                    Number(inputValue),
                    restrictionsData?.find(
                      (i) =>
                        i.restrictionName === "Payment-Fee" &&
                        i.status === "active"
                    )?.value
                  )
                )}{" "}
                {paymentCurrency}
              </p>
            </div>
          ) : null}

          {Number(
            restrictionsData?.find(
              (i) => i.restrictionName === "Wellma-Fee" && i.status === "active"
            )?.value
          ) ? (
            <div className={styles.paymentSectionItemDiv}>
              <p>Wellma Fee</p>
              <p className={styles.feeAndCurrency}>
                {calculatePercentage(
                  Number(inputValue),
                  restrictionsData?.find(
                    (i) =>
                      i.restrictionName === "Wellma-Fee" &&
                      i.status === "active"
                  )?.value
                )}{" "}
                {paymentCurrency}
              </p>
            </div>
          ) : null}

          <div className={styles.paymentSectionItemDiv}>
            <p>Total</p>
            <p className={styles.totalFeeAndCurrency}>
              {`${(
                inputValue +
                Number(
                  restrictionsData?.find(
                    (i) =>
                      i.restrictionName === "Payment-Fee" &&
                      i.status === "active"
                  )?.value
                    ? calculatePercentage(
                        Number(inputValue),
                        restrictionsData?.find(
                          (i) =>
                            i.restrictionName === "Payment-Fee" &&
                            i.status === "active"
                        )?.value
                      )
                    : 0
                ) +
                Number(
                  restrictionsData?.find(
                    (i) =>
                      i.restrictionName === "Wellma-Fee" &&
                      i.status === "active"
                  )?.value
                    ? calculatePercentage(
                        Number(inputValue),
                        restrictionsData?.find(
                          (i) =>
                            i.restrictionName === "Wellma-Fee" &&
                            i.status === "active"
                        )?.value
                      )
                    : 0
                )
              ).toFixed(2)}
              ${paymentCurrency}`}
            </p>
          </div>

          <Button
            style={{ margin: "24px 0" }}
            type="primary"
            block
            onClick={() => {
              makePaymentHandler([
                {
                  ...paymentItem,
                  price:
                    inputValue +
                    Number(
                      restrictionsData?.find(
                        (i) =>
                          i.restrictionName === "Payment-Fee" &&
                          i.status === "active"
                      )?.value
                        ? calculatePercentage(
                            Number(inputValue),
                            restrictionsData?.find(
                              (i) =>
                                i.restrictionName === "Payment-Fee" &&
                                i.status === "active"
                            )?.value
                          )
                        : 0
                    ) +
                    Number(
                      restrictionsData?.find(
                        (i) =>
                          i.restrictionName === "Wellma-Fee" &&
                          i.status === "active"
                      )?.value
                        ? calculatePercentage(
                            Number(inputValue),
                            restrictionsData?.find(
                              (i) =>
                                i.restrictionName === "Wellma-Fee" &&
                                i.status === "active"
                            )?.value
                          )
                        : 0
                    ),
                },
              ]);
              updateAppointmentFeePerSession(inputValue);
            }}
          >
            Proceed to Payment
          </Button>
        </div>
      ) : clientSecret ? (
        <>
          <Elements
            stripe={stripePromise}
            options={{
              clientSecret: clientSecret, // Pass the clientSecret to the Elements provider
              appearance: appearance,
            }}
          >
            <div className={styles.paymentContainer}>
              <PaymentElement /> {/* This displays the card input field */}
              <PaymentButton />
            </div>
          </Elements>
        </>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default Payment;
