import axios from "../config/axiosConfig";

export const updateSubscriptionStatusBySessionIdAPI = async (data, token) => {
  try {
    const response = await axios.put(
      `/api/updateSubscriptionStatusBySessionId`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw Error(error);
  }
};
