import axios from "../config/axiosConfig";

export const getCategoriesAPI = async () => {
  try {
    const response = await axios.get(
      `/api/getAllCategoryList`
    );
    return response;
  } catch (error) {
    throw Error(error);
  }
};

export const getCategoryDetailsAPI = async (id) => {
  try {
    const response = await axios.get(
      `/api/getCategoryDetailsById?id=${id}`
    );
    return response;
  } catch (error) {
    throw Error(error);
  }
};