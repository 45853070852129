import { Modal } from "antd";
import styles from "./RestrictionPopup.module.css";
import { useNavigate } from "react-router-dom";

const RestrictionPopUp = ({
  isOpen,
  onClose,
  title = "Unlock Full Access",
  description = "This feature is available exclusively for our Thrive (Premium) members. Upgrade now to enjoy unlimited access to all Wellma’s tools and benefits!",
}) => {
  const navigate = useNavigate();
  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      destroyOnClose={true}
      footer={null}
      title={title}
      width={450}
    >
      <div className={styles.container}>
        <p>{description}</p>
        <div
          className={styles.upgradeButton}
          onClick={() => navigate("/member-subscription")}
        >
          Upgrade
        </div>
      </div>
    </Modal>
  );
};

export default RestrictionPopUp;
