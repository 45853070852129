import { useAuth0 } from "@auth0/auth0-react";
import { Result } from "antd";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const Auth0LoginRedirect = () => {
  const { loginWithRedirect } = useAuth0();

  const [searchParams] = useSearchParams();

  const subscription = searchParams.get("subscription");

  useEffect(() => {
    if (subscription === "premium") {
      loginWithRedirect({
        appState: {
          returnTo: "/auth0Callback?subscription=premium",
        },
      });
    } else if (subscription === "basic") {
      loginWithRedirect({
        appState: {
          returnTo: "/auth0Callback?subscription=basic",
        },
      });
    } else {
      loginWithRedirect();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription]);

  return (
    <div>
      <Result title="You are being redirected to the Sign In / Sign Up page." />
    </div>
  );
};

export default Auth0LoginRedirect;
