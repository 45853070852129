import create from "zustand";
import { devtools, persist } from "zustand/middleware";
import { getMemberDetailsWithUserNameAPI } from "../api/memberApis";

export const useMemberStore = create(
  // devtools is for using redux devtools to visualize our store
  devtools(
    // persist will hold our store after we reload
    persist(
      (set) => ({
        // initialize the store
        member: {
          userName: null,
          jwt: null,
        },
        memberAccountInfo: {},
        // functions for modifying the store
        updateMemberDetails: (data) =>
          set((state) => ({ ...state, member: { ...state.member, ...data } })),
        getMemberAccountInfo: async (memberUserId) => {
          try {
            const response = await getMemberDetailsWithUserNameAPI(memberUserId);

            set((state) => ({
              ...state,
              memberAccountInfo: {
                ...state.memberAccountInfo,
                ...response.data.data,
              },
            }));
          } catch (error) {
            console.log("error :>> ", error);
          }
        },
        clearMember: () => {
          localStorage.clear();
          
          set((state) => ({ ...state, member: { userName: null, jwt: null } }));
        },
      }),
      //   this name will be present on the local storage for persisting the data
      { name: "user_data" }
    ),
    // this name will show on the dev tool
    { name: "MyStore" }
  )
);