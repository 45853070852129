import { useEffect, useState } from "react";
import { getWellnessKeywordsAPI } from "../../api/wellnessKeywordApis";
import styles from "./MemberListing.module.css";
import { getMemberListAPI } from "../../api/memberApis";
import LocationSearch from "../../components/LocationSearch";
import { Divider, Select, Spin } from "antd";
import { imageIsNotInS3 } from "../../utils";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

const MemberListing = () => {
  const [wellnessKeywords, setWellnessKeywords] = useState([]);
  const [selectedWellnessKeyword, setSelectedWellnessKeyword] = useState(null);
  const [selectedLocationData, setSelectedLocationData] = useState(null);
  const [membersList, setMembersList] = useState([]);
  const [loader, setLoader] = useState(false);

  const [wellnessKeywordListSection, setWellnessKeywordListSection] =
    useState(false);

  const getWellnessKeywordsAPIHelper = async () => {
    try {
      const response = await getWellnessKeywordsAPI();

      const finalWellnessKeywords = response?.data?.data?.length
        ? response?.data?.data?.map((i) => {
            return { label: i.name, value: i.id };
          })
        : [];
      setWellnessKeywords(finalWellnessKeywords);
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  const getMemberListAPIHelper = async (wellnessKeyword, location) => {
    setLoader(true);
    try {
      const response = await getMemberListAPI(
        [wellnessKeyword],
        location?.lat,
        location?.long,
        selectedLocationData ? 50 : 20100
      );

      setMembersList(response?.data?.rows);
      setLoader(false);
    } catch (error) {
      console.log("error :>> ", error);
      setLoader(false);
    }
  };

  useEffect(() => {
    getWellnessKeywordsAPIHelper();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.wrapper}>
      <Spin spinning={loader}>
        <div className={styles.inputs}>
          <Select
            showSearch
            style={{
              width: 300,
            }}
            placeholder="Search to Select Wellness Keyword"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={wellnessKeywords}
            value={selectedWellnessKeyword}
            onSelect={(val) => setSelectedWellnessKeyword(val)}
          />
          <div className={styles.locationInput}>
            <LocationSearch
              sendLocationInfo={setSelectedLocationData}
              defaultValue={
                selectedLocationData?.place ? selectedLocationData?.place : ""
              }
            />
          </div>

          {/* <div className={styles.searchButtonWrapper}> */}
          <button
            className={styles.searchButton}
            onClick={() =>
              getMemberListAPIHelper(
                selectedWellnessKeyword,
                selectedLocationData
              )
            }
          >
            Search
          </button>
          {/* </div> */}
        </div>

        <div className={styles.suggestedWellnessKeywordsSection}>
          {wellnessKeywords?.length
            ? wellnessKeywords.slice(0, 5).map((i) => (
                <p
                  className={
                    selectedWellnessKeyword === i?.value
                      ? styles.suggestedWellnessKeyword_selected
                      : styles.suggestedWellnessKeyword
                  }
                  onClick={() => {
                    if (selectedWellnessKeyword === i?.value) {
                      setSelectedWellnessKeyword(null);
                    } else {
                      getMemberListAPIHelper(i?.value, selectedLocationData);
                      setSelectedWellnessKeyword(i?.value);
                    }
                  }}
                >
                  {i?.label}
                </p>
              ))
            : null}

          <p
            onClick={() =>
              setWellnessKeywordListSection(!wellnessKeywordListSection)
            }
            className={styles.suggestedWellnessKeyword_all}
          >
            All {wellnessKeywordListSection ? <UpOutlined /> : <DownOutlined />}
          </p>
        </div>

        {wellnessKeywordListSection ? (
          <div className={styles.allWellnessKeywords}>
            {wellnessKeywords?.length
              ? wellnessKeywords?.map((i) => (
                  <p
                    className={styles.wellnessKeywordListItem}
                    onClick={() => {
                      setSelectedWellnessKeyword(i?.value);
                      setWellnessKeywordListSection(false);
                      getMemberListAPIHelper(i?.value, selectedLocationData);
                    }}
                  >
                    {i?.label}
                  </p>
                ))
              : null}
          </div>
        ) : null}

        <Divider />
        <div className={styles.memberSection}>
          <div className={styles.memberListWrapper}>
            {membersList?.length ? (
              membersList?.map((i) => {
                return (
                  <div key={i.id} className={styles.memberListCard}>
                    <div className={styles.memberListImageWrapper}>
                      <img
                        src={
                          imageIsNotInS3(i?.image)
                            ? `${i?.image}`
                            : `${process.env.REACT_APP_BASE_URL}${i?.image}`
                        }
                        alt="member pic"
                        className={styles.memberListImage}
                      />
                    </div>
                    <div className={styles.memberListInfoWrapper}>
                      <div className={styles.memberListInfo}>
                        <p className={styles.memberListName}>
                          {i.firstName} {i.lastName}
                        </p>
                        <p className={styles.memberListTitle}>{i.title}</p>
                      </div>

                      <div
                        className={styles.memberListButton}
                        onClick={() =>
                          window.open(
                            `${window.location.origin}/member/${i?.username}`,
                            "_blank"
                          )
                        }
                      >
                        View Details
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className={styles.searchIllustrationWrapper}>
                <img
                  className={styles.searchIllustrationImage}
                  src="/images/girl-search.png"
                  alt="search"
                />
              </div>
            )}
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default MemberListing;
