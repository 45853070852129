import axios from "../config/axiosConfig";

export const getMemberServicesAPI = async (username) => {
  try {
    const response = await axios.get(
      `/api/getMemberServicesByUser?username=${username}`
    );
    return response;
  } catch (error) {
    throw Error(error);
  }
};

export const createMemberServicesAPI = async (data, token) => {
  try {
    const response = await axios.post(`/api/createMemberServices`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw Error(error);
  }
};

export const updateMemberServicesAPI = async (data, token) => {
  try {
    const response = await axios.put(`/api/updateMemberServices`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw Error(error);
  }
};

export const deleteMemberServiceAPI = async (serviceId, token) => {
  try {
    const response = await axios.delete(
      `/api/deleteMemberServices?serviceId=${serviceId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw Error(error);
  }
};
