import axios from "../config/axiosConfig";

export const getMemberContentsAPI = async (username, token) => {
  try {
    const response = await axios.get(
      `/api/contentList?username=${username}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw Error(error);
  }
};

export const createMemberContentsAPI = async (data, token) => {
  try {
    const response = await axios.post(`/api/uploadContent`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw Error(error);
  }
};

export const updateMemberContentsAPI = async (data, token) => {
  try {
    const response = await axios.put(`/api/editContent`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw Error(error);
  }
};

export const deleteMemberContentAPI = async (contentId, token) => {
  try {
    const response = await axios.delete(
      `/api/deleteContent?id=${contentId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw Error(error);
  }
};
