import axios from "../config/axiosConfig";

export const updateUserDataAPI = async (data, token) => {
  try {
    const response = await axios.post(`/api/updateUserData`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    return response;
  } catch (error) {
    throw Error(error);
  }
};

export const getUserDataAPI = async (email, token) => {
  try {
    const response = await axios.get(`/api/getUserData?email=${email}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    return response;
  } catch (error) {
    throw Error(error);
  }
};