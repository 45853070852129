import axios from "../config/axiosConfig";

export const createAppointmentAPI = async (data, token) => {
  try {
    const response = await axios.post(`/api/createAppointment`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw Error(error);
  }
};

export const getAppointmentByUserAPI = async (token) => {
  try {
    const response = await axios.get(
      `/api/getAppointmentsByUser?limit=10&offset=0`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw Error(error);
  }
};

export const updateAppointmentAPI = async (token, data) => {
  try {
    const response = await axios.put(`/api/updateAppointment`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw Error(error);
  }
};

export const updateAppointmentFeePerSessionAPI = async (token, data) => {
  try {
    const response = await axios.put(`/api/updateAppointmentFeePerSession`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw Error(error);
  }
};

export const getBusyTimeByMemberAppointmentAPI = async (
  memberAuthId,
  selectedDate
) => {
  try {
    const response = await axios.get(
      `/api/getBusyTimeByMemberAppointment?memberAuthId=${memberAuthId}&selectedDate=${selectedDate}`
    );
    return response;
  } catch (error) {
    throw Error(error);
  }
};
