import React, { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import PaymentSuccess from "../../pages/PaymentCallback";

const PaymentButton = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [success, setSuccess] = useState(false);

  const baseUrl = window.location.origin;

  const handlePayment = async () => {
    if (!stripe || !elements) {
      console.error("Stripe.js has not yet loaded.");
      return;
    }

    setProcessing(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements, // Pass the elements instance to confirm the payment
      confirmParams: {
        return_url: `${baseUrl}/paymentCallback`, // Optional: for redirect after payment
      },
    });

    if (error) {
      setError(`Payment failed: ${error.message}`);
      setProcessing(false);
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      setSuccess(true);
      setError(null);
      setProcessing(false);
      console.log("Payment succeeded:", paymentIntent.id);
    }
  };

  return (
    <div>
      {error && <div style={{ color: "red" }}>{error}</div>}
      {success && <PaymentSuccess />}
      <button onClick={handlePayment} disabled={!stripe || processing}>
        {processing ? "Processing..." : "Pay"}
      </button>
    </div>
  );
};

export default PaymentButton;
