import styles from "./UserProfileRegistration.module.css";
import { getUserDataAPI, updateUserDataAPI } from "../../api/userApis";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { checkContainsEmoji, imageIsNotInS3 } from "../../utils";
import {
  Avatar,
  Button,
  Col,
  Form,
  Input,
  Row,
  Spin,
  Upload,
  message,
} from "antd";
import { useEffect, useState } from "react";
import PageWrapper from "../../components/PageWrapper";
import ImgCrop from "antd-img-crop";
import { UploadOutlined } from "@ant-design/icons";

const UserProfileRegistration = () => {
  const navigate = useNavigate();
  const { getIdTokenClaims, user } = useAuth0();
  const [searchParams] = useSearchParams();

  const subscription = searchParams.get("subscription");

  const [form] = Form.useForm();

  const [file, setFile] = useState({});
  const [avatar, setAvatar] = useState("");
  const [loader, setLoader] = useState(false);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    form.setFieldsValue({
      email: user?.email,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getUserDataAPIHelper = async () => {
    try {
      console.log("user", user);
      if (user) {
        const token = await getIdTokenClaims();
        const response = await getUserDataAPI(user.email, token?.__raw);
        console.log(
          "🚀 ~ file: index.jsx:276 ~ getUserDataAPIHelper ~ response:",
          response
        );
        setUserData(response?.data?.data);
        form.setFieldsValue({
          firstName: response?.data?.data?.firstName,
          lastName: response?.data?.data?.lastName,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getUserDataAPIHelper();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const onFinish = async (data) => {
    console.log("data :>> ", data);

    setLoader(true);

    const finalData = {
      ...data,
      email: user?.email,
      emailNotification: true,
      pushNotification: true,
    };

    // Validation Check - firstName
    if (finalData?.firstName && finalData?.firstName?.length > 30) {
      setLoader(false);
      message.error("First Name length should not be more than 30 letters");
      return;
    } else if (checkContainsEmoji(finalData?.firstName)) {
      setLoader(false);
      message.error("First Name should not contain any emoji");
      return;
    }

    // Validation Check - lastName
    if (finalData?.lastName && finalData?.lastName?.length > 30) {
      setLoader(false);
      message.error("Last Name length should not be more than 30 letters");
      return;
    } else if (checkContainsEmoji(finalData?.lastName)) {
      setLoader(false);
      message.error("Last Name should not contain any emoji");
      return;
    }

    const finalFormData = new FormData();

    // checking if the image is selected, then add it to the form data
    if (avatar) {
      finalFormData.append(
        "upload",
        file.blob,
        `${file.name}.${file.type.split("/")[1]}`
      );
    }

    for (let key in finalData) {
      finalFormData.append(key, finalData[key]);
    }

    try {
      const token = await getIdTokenClaims();
      await updateUserDataAPI(finalFormData, token?.__raw);

      setLoader(false);
      if (subscription === "premium") {
        navigate(`/member-register?subscription=premium`);
      } else if (subscription === "basic") {
        navigate(`/member-register?subscription=basic`);
      } else {
        navigate(`/become-a-member`);
      }
    } catch (error) {
      console.log(error);
      message.error("Something went wrong, Unable to Register");
      setLoader(false);
    }
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const customRequest = ({ file }) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const fileblob = new Blob([new Uint8Array(e.target.result)], {
        type: file.type,
      });

      setFile({ name: file.name, blob: fileblob, type: file.type });
    };

    getBase64(file, (imageUrl) => {
      setAvatar(imageUrl);
    });

    reader.readAsArrayBuffer(file);
  };

  return (
    <PageWrapper>
      <Spin spinning={loader}>
        <div className={styles.wrapper}>
          <div className={styles.formWrapper}>
            <h1 className={styles.formHeading}>User Profile Registration</h1>
            <Form
              form={form}
              onFinish={onFinish}
              onFinishFailed={() =>
                message.error("Please fill all the mandatory fields!")
              }
            >
              <Form.Item
                style={{
                  margin: "0 8px",
                }}
              >
                <Col
                  style={{
                    display: "inline-block",
                    width: "calc(50% - 8px)",
                  }}
                >
                  <p className={styles.formLabel}>
                    First Name <span>*</span>
                  </p>

                  <Form.Item
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: "First Name is required",
                      },
                    ]}
                  >
                    <Input
                      style={{
                        height: 60,
                      }}
                      placeholder="Please input your first name"
                    />
                  </Form.Item>
                </Col>

                <Col
                  style={{
                    display: "inline-block",
                    width: "calc(50% - 8px)",
                    margin: "0 8px",
                  }}
                >
                  <p className={styles.formLabel}>
                    Last Name <span>*</span>
                  </p>
                  <Form.Item
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: "Last Name is required",
                      },
                    ]}
                  >
                    <Input
                      style={{
                        height: 60,
                      }}
                      placeholder="Please input your last name"
                    />
                  </Form.Item>
                </Col>
              </Form.Item>

              <Row gutter={[48, 16]}>
                <Col span={10} style={{ marginLeft: "10px" }}>
                  <p className={styles.formLabel}>Image</p>
                  <ImgCrop
                    rotationSlider={true}
                    showReset={true}
                    modalTitle={"Crop Image"}
                  >
                    <Upload
                      name="avatar"
                      accept="image/*"
                      className={styles.acc_profile_box}
                      showUploadList={false}
                      customRequest={(data) => customRequest(data, "picture")}
                    >
                      <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </Upload>
                  </ImgCrop>
                </Col>
                <Col span={10}>
                  {avatar ? (
                    <Avatar
                      size={{
                        xs: 24,
                        sm: 32,
                        md: 40,
                        lg: 64,
                        xl: 80,
                        xxl: 100,
                      }}
                      src={avatar}
                    />
                  ) : userData?.image ? (
                    <Avatar
                      size={{
                        xs: 24,
                        sm: 32,
                        md: 40,
                        lg: 64,
                        xl: 80,
                        xxl: 100,
                      }}
                      src={
                        imageIsNotInS3(userData.image)
                          ? `${userData.image}`
                          : `${process.env.REACT_APP_BASE_URL}${userData.image}`
                      }
                    />
                  ) : null}
                </Col>
              </Row>

              <Form.Item>
                <button
                  style={{ background: "#518074", cursor: "pointer" }}
                  className={styles.formSubmitButton}
                  htmlType="submit"
                >
                  Continue
                </button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Spin>
    </PageWrapper>
  );
};

export default UserProfileRegistration;