import axios from "../config/axiosConfig";


export const uploadFileToS3API = async (data, token) => {
    try {
      const response = await axios.post(`/api/uploadFile`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      throw Error(error);
    }
  };

export const deleteFileFromS3API = async (data, token) => {
    try {
      const response = await axios.delete(`/api/deleteFile`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      throw Error(error);
    }
  };