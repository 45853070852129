import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./PaymentCallback.module.css";
import { useMemberStore } from "../../store/memberStore";
import { useEffect } from "react";
import { updateSubscriptionStatusBySessionIdAPI } from "../../api/subscription";
import { useAuth0 } from "@auth0/auth0-react";

const PaymentCallback = () => {
  const navigate = useNavigate();
  const memberAccountInfo = useMemberStore((state) => state.memberAccountInfo);
  const { getIdTokenClaims } = useAuth0();

  // Get the search params object
  const [searchParams] = useSearchParams();

  // Extract query parameters
  const paymentStatus = searchParams.get("redirect_status");
  const paymentSessionId = searchParams.get("session_id");

  const updateSubscriptionStatusBySessionIdHelper = async () => {
    try {
      const token = await getIdTokenClaims();
      const data = {
        sessionId: paymentSessionId,
        status: "success",
      };
      await updateSubscriptionStatusBySessionIdAPI(data, token?.__raw);

      navigate(`/member/${memberAccountInfo.username}`);
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  useEffect(() => {
    if (paymentSessionId) {
      if (paymentStatus === "succeeded") {
        updateSubscriptionStatusBySessionIdHelper();
      } else {
        setTimeout(() => {
          navigate(`/member/${memberAccountInfo.username}`);
        }, 3000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentSessionId]);

  return (
    <div className={styles.successPage}>
      {paymentStatus === "succeeded" ? (
        <h1 className={styles.greenTitle}>Payment Successful!</h1>
      ) : (
        <h1 className={styles.redTitle}>Payment Cancelled</h1>
      )}

      <p className={styles.message}>
        {!paymentSessionId
          ? paymentStatus === "succeeded"
            ? "Thank you for your purchase."
            : " Your payment process has been cancelled. Please try again."
          : "Please wait while we redirect you...!"}
      </p>

      {!paymentSessionId ? (
        paymentStatus === "succeeded" ? (
          <button
            className={styles.greenContinueButton}
            onClick={() => navigate("/appointments")}
          >
            Continue
          </button>
        ) : (
          <button
            className={styles.redContinueButton}
            onClick={() => navigate("/appointments")}
          >
            Continue
          </button>
        )
      ) : null}
    </div>
  );
};

export default PaymentCallback;
