import axios from "../config/axiosConfig";

export const updateGoogleCalendarCredentialDB = async ({access_token, user_authId, googleCredentialId, token}) => {
  try {
    const response = await axios.put(
      `/api/updateGoogleCalenderCredential`, {
        "calenderCredentialId": googleCredentialId,
        "access_token": access_token,
        "user_authId": user_authId
      },
      {headers: {
        Authorization: `Bearer ${token}`
      }}
    );
    return response;
  } catch (error) {
    throw Error(error);
  }
};