import { useCallback, useEffect, useState } from "react";
import { createSubscriptionCheckoutSessionAPI } from "../../api/paymentApis";
import { useAuth0 } from "@auth0/auth0-react";
import styles from "./MemberSubscription.module.css";
import { getMemberDetailsWithUserAuthIdAPI } from "../../api/memberApis";
import { getUserDataAPI } from "../../api/userApis";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";

const MemberSubscription = () => {
  const {
    user,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getIdTokenClaims,
  } = useAuth0();

  const navigate = useNavigate();

  const [memberData, setMemberData] = useState();
  const [loader, setLoader] = useState(false);

  const ifAuthenticatedHandler = useCallback(async () => {
    setLoader(true);

    const memberData = await getMemberDetailsWithUserAuthIdAPI(user.sub);
    if (memberData?.data?.data) {
      // if member we are not doing anything
      setMemberData(memberData?.data?.data);

      // navigate(`/member/${memberData?.data?.data?.username}`);
      // remain in this page for now

      setLoader(false);
    } else {
      try {
        const token = await getIdTokenClaims();
        const response = await getUserDataAPI(user.email, token?.__raw);
        if (
          response?.data?.data?.firstName &&
          response?.data?.data?.firstName?.length &&
          response?.data?.data?.lastName &&
          response?.data?.data?.lastName?.length &&
          response?.data?.data?.image &&
          response?.data?.data?.image?.length
        ) {
          setLoader(false);
          navigate(`/become-a-member`);
        } else {
          setLoader(false);
          navigate("/user-register");
        }
      } catch (error) {
        setLoader(false);
        console.log("error", error);
      }
    }
  }, [navigate, user, getIdTokenClaims]);

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        ifAuthenticatedHandler();
      } else {
        loginWithRedirect({
          appState: {
            returnTo: window.location.pathname,
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading, user]);

  const handleSubscribe = async () => {
    setLoader(true);
    try {
      const data = {
        priceId: process.env.REACT_APP_STRIPE_SUBSCRIPTION_PRICE_ID,
        planName: "memberPremium",
      };

      const token = await getIdTokenClaims();
      const response = await createSubscriptionCheckoutSessionAPI(
        data,
        token?.__raw
      );
      setLoader(false);
      window.location.href = response.data.url;
    } catch (error) {
      console.error("Error redirecting to Stripe:", error);
      setLoader(false);
    }
  };

  return (
    <div className={styles.pricingContainer}>
      <Spin spinning={loader}>
        <h1 className={styles.heading}>Choose Your Plan</h1>
        <div className={styles.pricingOptions}>
          {/* Basic Plan */}
          <div className={styles.plan}>
            <h2 className={styles.planTitle}>Basic</h2>
            <p className={styles.price}>FREE</p>
            <ul className={styles.features}>
              <li className={styles.feature}>Feature 1</li>
              <li className={styles.feature}>Feature 2</li>
              <li className={styles.feature}>Feature 3</li>
              <li className={styles.feature}>Feature 4</li>
              <li className={styles.feature}>Feature 5</li>
            </ul>
            {/* <button className={styles.ctaButton}>Choose Basic</button> */}
          </div>

          {/* Premium Plan */}
          <div className={styles.plan}>
            <h2 className={styles.planTitle}>Premium</h2>
            <p className={styles.price}>$9.99 / month</p>
            <ul className={styles.features}>
              <li className={styles.feature}>Feature 1</li>
              <li className={styles.feature}>Feature 2</li>
              <li className={styles.feature}>Feature 3</li>
              <li className={styles.feature}>Feature 4</li>
              <li className={styles.feature}>Feature 5</li>
            </ul>
            {memberData?.role === "premium" ? (
              <p>You are already a Premium member</p>
            ) : null}
            {memberData?.role === "basic" ? (
              <button className={styles.ctaButton} onClick={handleSubscribe}>
                Subscribe Now
              </button>
            ) : (
              <button className={styles.ctaButton}>Go to profile</button>
            )}
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default MemberSubscription;
