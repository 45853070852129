import { useParams } from "react-router-dom";
import styles from "./MemberIntro.module.css";
import { useMemberStore } from "../../store/memberStore";
import { useCallback, useEffect, useState } from "react";
import MemberAccountInfo from "../../components/MemberAccountInfo";
import MemberAccountAndTrainingInfo from "../../components/MemberAccountAndTrainingInfo";
import { Spin } from "antd";

const MemberIntro = () => {
  let { memberUserId } = useParams();

  const [loading, setLoading] = useState(false);
  console.log("🚀 ~ file: index.jsx:12 ~ MemberIntro ~ loading:", loading);

  const getMemberAccountInfo = useMemberStore(
    (state) => state.getMemberAccountInfo
  );

  const getMemberDetails = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getMemberAccountInfo(memberUserId);

      console.log("response : ", response);
    } catch (error) {
      console.log("error :>> ", error);
    } finally {
      setLoading(false);
    }
  }, [memberUserId, getMemberAccountInfo]);

  useEffect(() => {
    getMemberDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Spin spinning={loading}>
      <div className={styles.wrapper}>
        <div className={styles.leftSideWrapper}>
          <MemberAccountInfo showMail={false} />
        </div>
        <div className={styles.rightSideWrapper}>
          <MemberAccountAndTrainingInfo />
          <div className={styles.ctaContainer}>
            {/* new content */}
            <p className={styles.ctaHeading}>🤝 Get More with the Wellma App</p>
            <p>
              Unlock full access to practitioner profiles, sessions and
              exclusive wellness content. Download Wellma app here
            </p>
            <div className={styles.appStoreCtaSection}>
              <div className={styles.appStoreImagesWrapper}>
                <a href="https://apps.apple.com/id/app/healers-teachers/id6446088110">
                  <img
                    className={styles.appStoreImage}
                    src="https://images.squarespace-cdn.com/content/v1/630e7c042371f44087d4e6b3/5ed0e4da-d3c7-4a7e-a8ed-2f435123bb97/5a902db97f96951c82922874.png?format=300w"
                    alt="ios store img"
                  />
                </a>
                <a href="https://play.google.com/store/apps/details?gl=US&hl=en_US&id=com.healersandteachers.healersandteachers&pli=1">
                  <img
                    className={styles.playStoreImage}
                    src="https://images.squarespace-cdn.com/content/v1/630e7c042371f44087d4e6b3/0d02f6d4-1b57-46f1-990c-ab2a46ec7cea/google-play-badge.png?format=300w"
                    alt="android store img"
                  />
                </a>
              </div>
            </div>
            <p>🌟 Instant Connections: Direct access to wellness experts.</p>
            <p>
              🌱 Personalized Wellness: Customized content to suit your health
              goals.
            </p>
            <p>
              💬 Join the Conversation: Community space dedicated to holistic
              health.
            </p>
            {/* new content */}
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default MemberIntro;