import axios from "../config/axiosConfig";

export const getMemberDetailsWithUserNameAPI = async (memberUserName) => {
  try {
    const response = await axios.get(
      `/api/getMemberDetail?username=${memberUserName}`
    );
    return response;
  } catch (error) {
    throw Error(error);
  }
};

export const getMemberDetailsWithUserAuthIdAPI = async (memberAuthId) => {
  try {
    const response = await axios.get(
      `/api/getMemberDetail?authId=${memberAuthId}`
    );
    return response;
  } catch (error) {
    throw Error(error);
  }
};

export const loginAPI = async (email, password) => {
  try {
    const response = await axios.post(
      `/api/login`,
      {
        email: email,
        password: password,
      },
      {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("jwt")
              ? localStorage.getItem("jwt")
              : "NOT FOUND"
          }`,
        },
      }
    );
    return response;
  } catch (error) {
    throw Error(error);
  }
};

export const registrationAPI = async (data, token) => {
  try {
    const response = await axios.post(`/api/register`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw Error(error);
  }
};

export const updateMemberAPI = async (data, token) => {
  try {
    const response = await axios.put(`/api/updateMember`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw Error(error);
  }
};

export const updateMemberAndUserSettingsAPI = async (data, token) => {
  try {
    const response = await axios.put(`/api/updateMemberAndUserSettings`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw Error(error);
  }
};

export const getMemberListAPI = async (
  wellnessKeywordIds = [],
  latitude = 0.0,
  longitude = 0.0,
  range = 20100
) => {
  try {
    const response = await axios.get(
      `/api/getAllMembersList?wellnessKeywordIds=[${wellnessKeywordIds}]&latitude=${latitude}&longitude=${longitude}&range=${range}&limit=50&offset=0&sort=ASC&search=&virtualsessions=`
    );
    return response;
  } catch (error) {
    throw Error(error);
  }
};
