import styles from "./Footer.module.css";
import {
  FacebookFilled,
  InstagramOutlined,
  LinkedinOutlined,
} from "@ant-design/icons";

const Footer = () => {
  return (
    <div className={styles.footerWrapper}>
      <div className={styles.bottomFooterWrapper}>
        <div className={styles.footerSocialMediaWrapper}>
          <a
            className={styles.aTag}
            href="https://www.facebook.com/WellmaHealth"
          >
            <FacebookFilled />
          </a>
          <a
            className={styles.aTag}
            href="https://www.instagram.com/wellmahealth/"
          >
            <InstagramOutlined />
          </a>
          <a
            className={styles.aTag}
            href="https://www.linkedin.com/company/wellmahealth/"
          >
            <LinkedinOutlined />
          </a>
        </div>
        <div className={styles.copyrightText}>
          <p>Copyright © 2022-23 HT Collective, Inc. All rights reserved.</p>
          <p>
            <a href="https://www.wellmahealth.com/privacy-notice">
              Privacy Policy.
            </a>{" "}
            <a href="https://www.wellmahealth.com/cookies-policy">
              Cookies Policy.
            </a>{" "}
            <a href="https://www.wellmahealth.com/terms-of-service">
              Terms of Service.
            </a>{" "}
            <a href="https://www.wellmahealth.com/acceptable-use-policy">
              Acceptable Use Policy.
            </a>{" "}
            <a href="https://www.wellmahealth.com/disclaimer">Disclaimer.</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
