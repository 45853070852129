import { useMemberStore } from "../../store/memberStore";
import styles from "./MemberAccountInfo.module.css";
import {
  MailFilled,
  EnvironmentFilled,
  InstagramOutlined,
  FacebookOutlined,
  GlobalOutlined,
} from "@ant-design/icons";

import { imageIsNotInS3, openLinkInNewTab } from "../../utils";

const MemberAccountInfo = ({ showMail = true }) => {
  const memberAccountInfo = useMemberStore((state) => state.memberAccountInfo);

  return (
    <div className={styles.wrapper}>
      <div className={styles.memberInfoSection}>
        <div className={styles.memberImage}>
          <img
            src={
              imageIsNotInS3(memberAccountInfo.image)
                ? `${memberAccountInfo.image}`
                : `${process.env.REACT_APP_BASE_URL}${memberAccountInfo.image}`
            }
            alt="profile pic"
          />
        </div>
        <div className={styles.memberIntro}>
          <div className={styles.memberName}>
            {`${
              memberAccountInfo?.firstName ? memberAccountInfo?.firstName : ""
            } ${
              memberAccountInfo?.lastName ? memberAccountInfo?.lastName : ""
            }`}
          </div>
          <div className={styles.memberTitle}>
            {memberAccountInfo?.title ? memberAccountInfo?.title : ""}
          </div>
        </div>
      </div>
      {memberAccountInfo?.languages?.length ? (
        <div className={styles.memberExpertiseSection}>
          <div className={styles.memberExpertiseTitle}>Languages</div>
          <div className={styles.memberExpertiseWrapper}>
            {memberAccountInfo?.languages
              ? memberAccountInfo?.languages?.map((i) => (
                  <div className={styles.memberLanguageItem} key={i.id}>
                    {i.name}
                  </div>
                ))
              : null}
          </div>
        </div>
      ) : null}
      <div className={styles.memberExpertiseSection}>
        <div className={styles.memberExpertiseTitle}>Expertise</div>
        <div className={styles.memberExpertiseWrapper}>
          {memberAccountInfo?.wellnessKeywords?.length
            ? memberAccountInfo?.wellnessKeywords?.map((i) => (
                <div className={styles.memberExpertiseItem} key={i.id}>
                  {i.name}
                </div>
              ))
            : null}
        </div>
      </div>
      {showMail ? (
        <div className={styles.memberEmail}>
          {" "}
          <MailFilled className={styles.memberEmailIcon} />{" "}
          <a
            href={`mailto:${
              memberAccountInfo?.email ? memberAccountInfo?.email : ""
            }`}
            className={styles.memberEmailLink}
          >
            {memberAccountInfo?.email ? memberAccountInfo?.email : ""}
          </a>
        </div>
      ) : null}
      <div className={styles.memberLocation}>
        <EnvironmentFilled className={styles.memberLocationIcon} />
        <div className={styles.memberLocationText}>
          {memberAccountInfo?.location ? memberAccountInfo?.location : ""}
        </div>
      </div>
      <div className={styles.socialLinksContainer}>
        {memberAccountInfo?.instagram ? (
          <InstagramOutlined
            className={styles.socialLinkIcon}
            onClick={() => openLinkInNewTab(memberAccountInfo?.instagram)}
          />
        ) : null}

        {memberAccountInfo?.facebook ? (
          <FacebookOutlined
            className={styles.socialLinkIcon}
            onClick={() => openLinkInNewTab(memberAccountInfo?.facebook)}
          />
        ) : null}

        {memberAccountInfo?.website ? (
          <GlobalOutlined
            className={styles.socialLinkIcon}
            onClick={() => openLinkInNewTab(memberAccountInfo?.website)}
          />
        ) : null}
      </div>
      {/* <div className={styles.memberVirtualSessions}>
        <VideoCameraFilled className={styles.memberVirtualSessionsIcon} />
        <div className={styles.memberVirtualSessionsText}>
          {memberAccountInfo?.virtualsessions === "y"
            ? "Virtual Sessions"
            : memberAccountInfo?.virtualsessions === "n"
            ? "In-Person Sessions"
            : "Virtual and In-Person Sessions"}
        </div>
      </div> */}
    </div>
  );
};

export default MemberAccountInfo;