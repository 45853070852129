import { useEffect, useState } from "react";
import styles from "./Header.module.css";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { Avatar, Button, Drawer, Dropdown, Modal, Result } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const { isAuthenticated, logout, loginWithRedirect, user } = useAuth0();
  const navigate = useNavigate();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Attach resize listener
    window.addEventListener("resize", handleResize);

    // Clean up listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [changePasswordSuccessModal, setChangePasswordSuccessModal] =
    useState(false);

  const changePassword = async () => {
    try {
      await axios.post(
        `https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`,
        {
          client_id: process.env.REACT_APP_AUTH0_CLIENTID,
          email: user.email,
          connection: "Username-Password-Authentication",
        }
      );

      setChangePasswordSuccessModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.headerNavigationWrapper}>
        <div className={styles.headerLogo}>
          <img src="/images/wellmalogo.png" alt="logo" />
        </div>
        {windowWidth >= 768 ? (
          <div className={styles.navigation}>
            <div className={styles.navigationItem}>
              <a href="https://www.wellmahealth.com/">Home</a>
            </div>
            <div className={styles.navigationItem}>
              <a href="https://www.wellmahealth.com/become-a-member">
                List your practice
              </a>
            </div>
            <div className={styles.navigationItem}>
              <a href="https://www.wellmahealth.com/aboutus">About Us</a>
            </div>

            {isAuthenticated ? (
              <Dropdown
                menu={
                  !user?.sub?.includes("google") ||
                  !user?.sub?.includes("apple")
                    ? {
                        items: [
                          {
                            key: "1",
                            label: (
                              <div
                                className={styles.navigationButton}
                                onClick={() =>
                                  logout({
                                    logoutParams: {
                                      returnTo:
                                        "https://www.wellmahealth.com/",
                                    },
                                  })
                                }
                              >
                                Log Out
                              </div>
                            ),
                          },
                          {
                            key: "2",
                            label: (
                              <div
                                className={styles.navigationButton}
                                onClick={changePassword}
                              >
                                change password
                              </div>
                            ),
                          },
                          {
                            key: "3",
                            label: (
                              <div
                                className={styles.navigationButton}
                                onClick={()=>navigate(`/appointments`)}
                              >
                                Appointments
                              </div>
                            ),
                          },
                        ],
                      }
                    : {
                        items: [
                          {
                            key: "1",
                            label: (
                              <div
                                className={styles.navigationButton}
                                onClick={() =>
                                  logout({
                                    logoutParams: {
                                      returnTo:
                                        "https://www.wellmahealth.com/",
                                    },
                                  })
                                }
                              >
                                Log Out
                              </div>
                            ),
                          },
                        ],
                      }
                }
                placement="bottomLeft"
                arrow
              >
                <Avatar
                  style={{ cursor: "pointer" }}
                  size={40}
                  icon={<UserOutlined />}
                />
              </Dropdown>
            ) : (
              <div className={styles.navigationItem}>
                <button
                  className={styles.navigationButton}
                  onClick={loginWithRedirect}
                >
                  Login
                </button>
              </div>
            )}
          </div>
        ) : (
          <>
            <div className={styles.navigationButton} onClick={showDrawer}>
              ☰
            </div>
            <Drawer
              title="Menu"
              placement="right"
              onClose={onClose}
              visible={visible}
              width={250}
            >
              <div className={styles.navigation}>
                <div className={styles.navigationItem}>
                  <a href="https://www.wellmahealth.com/">Home</a>
                </div>
                <div className={styles.navigationItem}>
                  <a href="https://www.wellmahealth.com/become-a-member">
                    List your practice
                  </a>
                </div>
                <div className={styles.navigationItem}>
                  <a href="https://www.wellmahealth.com/aboutus">
                    About Us
                  </a>
                </div>

                {isAuthenticated ? (
                  <Dropdown
                    menu={
                      !user?.sub?.includes("google") ||
                      !user?.sub?.includes("apple")
                        ? {
                            items: [
                              {
                                key: "1",
                                label: (
                                  <div
                                    className={styles.navigationButton}
                                    onClick={() =>
                                      logout({
                                        logoutParams: {
                                          returnTo:
                                            "https://www.wellmahealth.com/",
                                        },
                                      })
                                    }
                                  >
                                    Log Out
                                  </div>
                                ),
                              },
                              {
                                key: "2",
                                label: (
                                  <div
                                    className={styles.navigationButton}
                                    onClick={changePassword}
                                  >
                                    change password
                                  </div>
                                ),
                              },
                              {
                                key: "3",
                                label: (
                                  <div
                                    className={styles.navigationButton}
                                    onClick={()=>navigate(`/appointments`)}
                                  >
                                    Appointments
                                  </div>
                                ),
                              },
                            ],
                          }
                        : {
                            items: [
                              {
                                key: "1",
                                label: (
                                  <div
                                    className={styles.navigationButton}
                                    onClick={() =>
                                      logout({
                                        logoutParams: {
                                          returnTo:
                                            "https://www.wellmahealth.com/",
                                        },
                                      })
                                    }
                                  >
                                    Log Out
                                  </div>
                                ),
                              },
                            ],
                          }
                    }
                    placement="bottomLeft"
                    arrow
                  >
                    <Avatar
                      style={{ cursor: "pointer" }}
                      size={40}
                      icon={<UserOutlined />}
                    />
                  </Dropdown>
                ) : (
                  <div className={styles.navigationItem}>
                    <button
                      className={styles.navigationButton}
                      onClick={loginWithRedirect}
                    >
                      Login
                    </button>
                  </div>
                )}
              </div>
            </Drawer>
          </>
        )}
      </div>

      {changePasswordSuccessModal ? (
        <Modal
          open={changePasswordSuccessModal}
          onCancel={() => setChangePasswordSuccessModal(false)}
          footer={null}
        >
          <Result
            status="success"
            title="Change password requested successfully"
            subTitle="One email sent to your registerd email address, which will contain a link to change your password. You can change your password by clicking the link in the email."
            extra={[
              <Button
                type="primary"
                key="console"
                onClick={() => setChangePasswordSuccessModal(false)}
              >
                Okay
              </Button>,
            ]}
          />
        </Modal>
      ) : null}
    </div>
  );
};

export default Header;
