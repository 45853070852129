import create from "zustand";
import { devtools, persist } from "zustand/middleware";
import { getRestrictionsAPI } from "../api/restrictionsApis";

export const useRestrictionStore = create(
  // devtools is for using redux devtools to visualize our store
  devtools(
    // persist will hold our store after we reload
    persist(
      (set) => ({
        // initialize the store
        restrictionsData: [],
        // functions for modifying the store
        getAllRestrictionDataInfo: async () => {
          try {
            const response = await getRestrictionsAPI();

            set((state) => ({
              ...state,
              restrictionsData: [...response.data.data],
            }));
          } catch (error) {
            console.log("error :>> ", error);
          }
        },
        clearRestrictions: () => {
          localStorage.clear();

          set((state) => ({ ...state, restrictionsData: [] }));
        },
      }),
      //   this name will be present on the local storage for persisting the data
      { name: "restriction_data" }
    ),
    // this name will show on the dev tool
    { name: "RestrictionStore" }
  )
);
