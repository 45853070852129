import {
  Avatar,
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Spin,
  TimePicker,
  Tooltip,
  Tree,
  Upload,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import {
  createMemberServicesAPI,
  updateMemberServicesAPI,
} from "../../api/memberServicesApis";
import styles from "./MemberServiceAddEditModal.module.css";
import { UploadOutlined, CloseOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import {
  antdMinNumberValidator,
  antdMinNumberValidatorIDR,
  antdValidateNumber,
  imageIsNotInS3,
} from "../../utils";
import { useAuth0 } from "@auth0/auth0-react";
import { stockImageUrls } from "../../constants";
import { getCategoriesAPI } from "../../api/categoryApis";
import { getWellnessKeywordsAPI } from "../../api/wellnessKeywordApis";
import currencyData from "../../constants/currency.json";
import RestrictionPopUp from "../RestrictionPopup";
import { useRestrictionStore } from "../../store/restrictions";
import { useMemberStore } from "../../store/memberStore";

const { Option } = Select;

const MemberServiceAddEditModal = ({ isOpen, onClose, editData }) => {
  const [feeType, setFeeType] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [file, setFile] = useState({});
  const [avatar, setAvatar] = useState("");
  const [categories, setCategories] = useState([]);
  const [wellnessKeywords, setWellnessKeywords] = useState([]);
  const [selectedWellnessKeyword, setSelectedWellnessKeyword] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showRestrictionPopUp, setShowRestrictionPopUp] = useState(false);

  const memberAccountInfo = useMemberStore((state) => state.memberAccountInfo);
  const restrictionsData = useRestrictionStore(
    (state) => state.restrictionsData
  );

  const [form] = Form.useForm();
  const { getIdTokenClaims } = useAuth0();

  const onFinish = async (data, publish = false) => {
    setLoader(true);

    if (!selectedWellnessKeyword?.length) {
      message.error("Wellness practices is mandatory!");
      setLoader(false);
      return;
    }

    if (selectedWellnessKeyword?.length > 5) {
      message.error("Maximum wellness practice length is 5!");
      setLoader(false);
      return;
    }

    if (
      feeType === "sliding scale" &&
      Number(data?.slidingscalemin) > Number(data?.slidingscalemax)
    ) {
      message.error(
        "Sliding scale max needs to be bigger than Sliding scale min"
      );
      setLoader(false);
      return;
    }

    const finalData = {
      ...data,
      duration: String(dayjs(data.duration).format("H:mm")),
      publish: publish ? publish : null,
      wellnesskeywords: JSON.stringify({
        existing: selectedWellnessKeyword,
        new: [],
      }),
    };

    // Validation Check - name
    if (finalData?.name && finalData?.name?.length > 100) {
      setLoader(false);
      message.error("Name length should not be more than 100 letters");
      return;
    }

    // Validation Check - description
    if (finalData?.description && finalData?.description?.length > 1500) {
      setLoader(false);
      message.error("Description length should not be more than 1500 letters");
      return;
    }

    const finalFormData = new FormData();

    // checking if the image is selected, then add it to the form data
    if (selectedImage) {
      const response = await fetch(selectedImage);
      const imageBlob = await response.blob();
      finalFormData.append("upload", imageBlob);
    } else if (avatar) {
      finalFormData.append(
        "upload",
        file.blob,
        `${file.name}.${file.type.split("/")[1]}`
      );
    }

    for (let key in finalData) {
      finalFormData.append(key, finalData[key]);
    }

    try {
      const token = await getIdTokenClaims();
      if (editData) {
        finalFormData.append("id", editData.id);
        await updateMemberServicesAPI(finalFormData, token.__raw);
      } else {
        await createMemberServicesAPI(finalFormData, token.__raw);
      }
      message.info(`Service ${editData ? "Edited" : "Added"} Successfully`);
      setLoader(false);
      onClose();
    } catch (error) {
      console.log(error);
      message.error(
        `Something went wrong, Unable to ${
          editData ? "Edit" : "Add"
        } the Service`
      );
      setLoader(false);
    }
  };

  const getCategoriesAPIHelper = async () => {
    try {
      const response = await getCategoriesAPI();
      setCategories(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllWellnessKeywordHelper = async () => {
    try {
      const response = await getWellnessKeywordsAPI();
      setWellnessKeywords(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCategoriesAPIHelper();
    getAllWellnessKeywordHelper();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (editData) {
      form.setFieldsValue({
        ...editData,
        duration: dayjs(editData.duration, "HH:mm"),
      });
      setSelectedWellnessKeyword(
        editData.wellnessKeywords.length
          ? editData.wellnessKeywords.map((i) => i.id)
          : []
      );
      setFeeType(editData.feetype);
      setSelectedCurrency(editData.currency);
    }
  }, [editData, form]);

  const handleFileRetrieval = () => {
    if (selectedImage !== null) {
      const selectedFile = new File([selectedImage], selectedImage, {
        type: "image/jpeg",
      });
      customRequest({ file: selectedFile, takeAvatar: selectedImage });
    }
  };

  useEffect(() => {
    if (selectedImage) {
      handleFileRetrieval();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedImage]);

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const customRequest = ({ file, takeAvatar = "" }) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const fileblob = new Blob([new Uint8Array(e.target.result)], {
        type: file.type,
      });

      setFile({ name: file.name, blob: fileblob, type: file.type });
    };

    if (takeAvatar) {
      setAvatar(takeAvatar);
    } else {
      getBase64(file, (imageUrl) => {
        setAvatar(imageUrl);
      });
    }

    reader.readAsArrayBuffer(file);
  };
  return (
    <Modal
      title={editData ? "Service update form" : "Service add form"}
      open={isOpen}
      onCancel={onClose}
      destroyOnClose={true}
      footer={null}
      width={800}
    >
      <Spin spinning={loader}>
        <Form form={form}>
          <Col
            style={{
              display: "inline-block",
              width: "97%",
              margin: "0 8px",
            }}
          >
            <p className={styles.formLabel}>
              Name <span>*</span>
            </p>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Name is required",
                },
              ]}
            >
              <Input
                style={{
                  height: 60,
                }}
                placeholder="Please provide service Name"
              />
            </Form.Item>
          </Col>

          <Col
            style={{
              display: "inline-block",
              width: "97%",
              margin: "0 8px",
            }}
          >
            <p className={styles.formLabel}>
              Description <span>*</span>
            </p>
            <Form.Item
              name="description"
              rules={[
                {
                  required: true,
                  message: "Description is required",
                },
              ]}
            >
              <Input.TextArea
                maxLength={1500}
                showCount={true}
                rows={12}
                placeholder="Please provide service Description"
              />
            </Form.Item>
          </Col>

          <Col
            style={{
              display: "inline-block",
              width: "97%",
              margin: "24px 8px 0",
            }}
          >
            <p className={styles.formLabel} styles={{ marginBottom: "7px" }}>
              Duration(HH:MM) <span>*</span>
            </p>
            <Form.Item
              name="duration"
              rules={[
                {
                  required: true,
                  message: "Duration is required",
                },
              ]}
            >
              <TimePicker showNow={false} format={"HH:mm"} />
            </Form.Item>
          </Col>

          <Col
            style={{
              display: "inline-block",
              width: "97%",
              margin: "0 8px",
            }}
          >
            <p className={styles.formLabel}>
              Service Type <span>*</span>
            </p>
            <Form.Item
              name="type"
              rules={[
                {
                  required: true,
                  message: "Service type is required",
                },
              ]}
            >
              <Select
                placeholder="Select a option and change input text above"
                allowClear
              >
                <Option value="in-person">In Person</Option>
                <Option value="virtual">Virtual</Option>
                <Option value="both">Both</Option>
              </Select>
            </Form.Item>
          </Col>
          <Row>
            <p className={styles.formLabel}>
              Wellness practices (select up to 5 wellness practices that apply
              to your work from the list below) <span>*</span>
            </p>
            <Col
              style={{
                display: "inline-block",
                width: "45%",
                margin: "0 8px",
              }}
            >
              <Form.Item name="wellnesskeywords">
                <Tree
                  checkable
                  showLine
                  style={{ width: 300 }}
                  checkedKeys={selectedWellnessKeyword}
                  onCheck={(val) => setSelectedWellnessKeyword(val)}
                  treeData={
                    categories.length
                      ? categories.map((i) => ({
                          key: i.name,
                          title: i.name,
                          checkable: false,
                          children: i?.wellnessKeywords?.length
                            ? i?.wellnessKeywords?.map((j) => ({
                                key: j.id,
                                title: j.name,
                                selectable: true,
                              }))
                            : [],
                        }))
                      : []
                  }
                />
              </Form.Item>
            </Col>
            <Col
              style={{
                display: "inline-block",
                width: "45%",
                margin: "0 8px",
              }}
            >
              {selectedWellnessKeyword?.length > 0 ? (
                <div>
                  <h3>Selected Wellness Keywords (Max 5)</h3>
                  {selectedWellnessKeyword?.length
                    ? selectedWellnessKeyword?.map((i) => (
                        <p>
                          {wellnessKeywords?.find((j) => j.id === i)?.name}{" "}
                          &nbsp;{" "}
                          <CloseOutlined
                            onClick={(e) => {
                              e.preventDefault();
                              setSelectedWellnessKeyword(
                                selectedWellnessKeyword.filter((p) => p !== i)
                              );
                            }}
                            style={{ color: "red", cursor: "pointer" }}
                          />
                        </p>
                      ))
                    : null}
                </div>
              ) : (
                <p>No Wellness Keywords Selected</p>
              )}
            </Col>
          </Row>

          <Col
            style={{
              display: "inline-block",
              width: "97%",
              margin: "0 8px",
            }}
          >
            <p className={styles.formLabel}>
              Fee Type <span>*</span>
            </p>
            <Form.Item
              name="feetype"
              rules={[
                {
                  required: true,
                  message: "Fee Type is required",
                },
              ]}
            >
              <Select
                placeholder="Select a option and change input text above"
                allowClear
                onChange={setFeeType}
              >
                <Option value="free">Free</Option>
                <Option value="fixed">Fixed price</Option>
                <Option value="sliding scale">Sliding scale</Option>
              </Select>
            </Form.Item>
          </Col>

          {feeType === "sliding scale" || feeType === "fixed" ? (
            <Col
              style={{
                display: "inline-block",
                width: "97%",
                margin: "0 8px",
              }}
            >
              <p className={styles.formLabel}>
                Currency <span>*</span>
              </p>
              <Form.Item
                name="currency"
                rules={[
                  {
                    required: true,
                    message: "Currency Type is required",
                  },
                ]}
              >
                <Select
                  placeholder="Select a option and change input text above"
                  allowClear
                  onChange={setSelectedCurrency}
                >
                  {currencyData?.map((currency) => (
                    <Option value={currency?.currency}>
                      {currency?.country} - ({currency?.currency})
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          ) : null}

          {feeType === "sliding scale" && (
            <Col
              style={{
                display: "inline-block",
                width: "97%",
                margin: "0 8px",
              }}
            >
              <p className={styles.formLabel}>
                Minimum Fee <span>*</span>
              </p>
              <Form.Item
                name="slidingscalemin"
                rules={[
                  {
                    required: true,
                    message: "Minimum Fee is required",
                  },
                  {
                    validator: antdValidateNumber,
                  },
                  {
                    validator:
                      selectedCurrency === "IDR"
                        ? antdMinNumberValidatorIDR
                        : antdMinNumberValidator,
                  },
                ]}
              >
                <Input
                  style={{
                    height: 60,
                  }}
                  prefix={selectedCurrency}
                  placeholder={`Please input your Minimum Fee (min - ${
                    selectedCurrency === "IDR" ? "100,000" : "1"
                  }`}
                />
              </Form.Item>
            </Col>
          )}

          {feeType === "sliding scale" && (
            <Col
              style={{
                display: "inline-block",
                width: "97%",
                margin: "0 8px",
              }}
            >
              <p className={styles.formLabel}>
                Maximum Fee <span>*</span>
              </p>
              <Form.Item
                name="slidingscalemax"
                rules={[
                  {
                    required: true,
                    message: "Maximum Fee is required",
                  },
                  {
                    validator: antdValidateNumber,
                  },
                ]}
              >
                <Input
                  style={{
                    height: 60,
                  }}
                  prefix={selectedCurrency}
                  placeholder="Please input your Maximum Fee"
                />
              </Form.Item>
            </Col>
          )}
          {feeType === "fixed" && (
            <Col
              style={{
                display: "inline-block",
                width: "97%",
                margin: "0 8px",
              }}
            >
              <p className={styles.formLabel}>
                Fee Per Session <span>*</span>
              </p>
              <Form.Item
                name="feepersession"
                rules={[
                  {
                    required: true,
                    message: "Fee Per Session is required",
                  },
                  {
                    validator: antdValidateNumber,
                  },
                  {
                    validator:
                      selectedCurrency === "IDR"
                        ? antdMinNumberValidatorIDR
                        : antdMinNumberValidator,
                  },
                ]}
              >
                <Input
                  style={{
                    height: 60,
                  }}
                  prefix={selectedCurrency}
                  placeholder={`Please input your Fee Per Session  (min - ${
                    selectedCurrency === "IDR" ? "100,000" : "1"
                  })`}
                />
              </Form.Item>
            </Col>
          )}

          <p className={styles.formLabel} style={{ paddingLeft: "10px" }}>
            Image
          </p>
          <div className={styles.ImageUploadSection}>
            <div className={styles.avatarSectionWrapper}>
              {avatar ? (
                <Avatar
                  size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                  src={avatar}
                />
              ) : editData ? (
                <Avatar
                  size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                  src={
                    imageIsNotInS3(editData.image)
                      ? `${editData.image}`
                      : `${process.env.REACT_APP_BASE_URL}${editData.image}`
                  }
                />
              ) : null}
            </div>
            <div>
              <ImgCrop
                rotationSlider={true}
                showReset={true}
                modalTitle={"Crop Image"}
              >
                <Upload
                  name="avatar"
                  accept="image/*"
                  className={styles.acc_profile_box}
                  showUploadList={false}
                  customRequest={(data) => customRequest(data, "picture")}
                >
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
              </ImgCrop>
            </div>
          </div>

          <div className={styles.imagesDivider}>
            Or Select from Our Stock Images
          </div>

          <div className={styles.stockImageHorizontalList}>
            {stockImageUrls.map((imagePath, itemIndex) => (
              <div key={itemIndex} className={styles.preloadedImageWrapper}>
                <img
                  key={imagePath}
                  src={imagePath}
                  alt="Preloaded"
                  onClick={() => setSelectedImage(imagePath)}
                  className={
                    selectedImage === imagePath
                      ? styles.preloadedImageSelected
                      : styles.preloadedImage
                  }
                />
              </div>
            ))}
          </div>

          <Row>
            <Col span={17}>
              <Tooltip title="This will ony update the Service, it's not going to post this Service update in the community feed">
                <button
                  className={styles.formSubmitButton}
                  onClick={() => {
                    form
                      .validateFields()
                      .then((value) => {
                        console.log("value", value);
                        onFinish(value);
                      })
                      .catch((error) => {
                        console.log("error", error);
                      });
                  }}
                >
                  {editData ? "Update Only" : "Submit Only"}
                </button>
              </Tooltip>
            </Col>
            <Col span={7}>
              <Tooltip title="This will update the Service and post this Service update in the community feed also">
                <button
                  className={styles.formSubmitButton}
                  onClick={() => {
                    form
                      .validateFields()
                      .then((value) => {
                        restrictionsData?.length &&
                        restrictionsData?.find(
                          (i) =>
                            i.restrictionName === "Publish-Feed" &&
                            i.status === "active"
                        ) &&
                        memberAccountInfo?.role === "basic"
                          ? Number(
                              restrictionsData?.find(
                                (i) =>
                                  i.restrictionName === "Publish-Feed" &&
                                  i.status === "active"
                              )?.value
                            )
                            ? onFinish(value, true)
                            : setShowRestrictionPopUp(true)
                          : onFinish(value, true);
                      })
                      .catch((error) => {
                        console.log("error", error);
                      });
                  }}
                >
                  {editData ? "Update and Publish" : "Submit and Publish"}
                </button>
              </Tooltip>
            </Col>
          </Row>
        </Form>
      </Spin>

      {showRestrictionPopUp ? (
        <RestrictionPopUp
          isOpen={showRestrictionPopUp}
          onClose={() => setShowRestrictionPopUp(false)}
        />
      ) : null}
    </Modal>
  );
};

export default MemberServiceAddEditModal;
