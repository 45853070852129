import axios from "../config/axiosConfig";

export const getWellnessKeywordsAPI = async () => {
  try {
    const response = await axios.get(
      `/api/getAllWellnessKeywords`
    );
    return response;
  } catch (error) {
    throw Error(error);
  }
};
