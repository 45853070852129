import {
  Avatar,
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Tree,
  Upload,
} from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { updateMemberAndUserSettingsAPI } from "../../api/memberApis";
import { useMemberStore } from "../../store/memberStore";
import styles from "./MemberAccountInfoEditModal.module.css";
import {
  DeleteOutlined,
  CloseOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import LocationSearch from "../LocationSearch";
import { useAuth0 } from "@auth0/auth0-react";
import "react-phone-input-2/lib/style.css";
import { checkContainsEmoji, imageIsNotInS3 } from "../../utils";
import { getCategoriesAPI } from "../../api/categoryApis";
import { getWellnessKeywordsAPI } from "../../api/wellnessKeywordApis";
import ImgCrop from "antd-img-crop";
import PhoneInput from "react-phone-input-2";
import { getAllLanguagesAPI } from "../../api/languages";

const { Option } = Select;

const MemberAccountInfoEditModal = ({ isOpen, onClose, editData }) => {
  let { memberUserId } = useParams();
  const { getIdTokenClaims } = useAuth0();

  const [categories, setCategories] = useState([]);
  const [wellnessKeywords, setWellnessKeywords] = useState([]);
  const [allLanguages, setAllLanguages] = useState([]);
  const [selectedWellnessKeyword, setSelectedWellnessKeyword] = useState([]);
  const [selectedLocationData, setSelectedLocationData] = useState({});
  const [loader, setLoader] = useState(false);
  const [file, setFile] = useState({});
  const [avatar, setAvatar] = useState("");

  const [trainingAndCredentialsInputList, setTrainingAndCredentialsInputList] =
    useState([]);

  const [
    existingTrainingAndCredentialsInputList,
    setExistingTrainingAndCredentialsInputList,
  ] = useState([]);

  const getMemberAccountInfo = useMemberStore(
    (state) => state.getMemberAccountInfo
  );

  const [form] = Form.useForm();

  const onFinish = async (data) => {
    setLoader(true);

    if (!selectedWellnessKeyword?.length) {
      message.error("Wellness practices is mandatory!");
      setLoader(false);
      return;
    }

    if (selectedWellnessKeyword?.length > 5) {
      message.error("Maximum wellness practice length is 5!");
      setLoader(false);
      return;
    }

    if (
      !selectedLocationData?.lat &&
      !selectedLocationData?.long &&
      !selectedLocationData?.place
    ) {
      message.error("Location is mandatory!");
      setLoader(false);
      return;
    }

    const qualificationArray = [];

    for (const key in data) {
      if (key.includes("qualification") && data[key]) {
        if (key.split("-")[1]?.length < 4) {
          qualificationArray.push({ data: data[key], id: key.split("-")[1] });
        } else {
          qualificationArray.push({ data: data[key] });
        }
        delete data[key];
      }
    }

    if (!qualificationArray?.length) {
      message.error("Qualification is mandatory!");
      setLoader(false);
      return;
    }

    data.qualification = JSON.stringify(qualificationArray);

    const finalData = {
      ...data,
      phoneno: `+${data?.phoneno ? data?.phoneno?.replace(/\+/g, "") : null}`,
      languages: data?.languages
        ? data?.languages[0]?.value
          ? data?.languages.map((i) => i.value)
          : data?.languages
        : "",
      latitude: selectedLocationData?.lat,
      longitude: selectedLocationData?.long,
      location: selectedLocationData?.place,
      virtualsessions: "both",
      wellnesskeywords: JSON.stringify({
        existing: selectedWellnessKeyword,
        new: [],
      }),
    };

    // Validation Check - website
    if (finalData?.website && finalData?.website?.length > 100) {
      setLoader(false);
      message.error("Website length should not be more than 100 letters");
      return;
    }

    // Validation Check - numberOfYearsInPractice
    if (
      finalData?.numberOfYearsInPractice &&
      finalData?.numberOfYearsInPractice?.length > 100
    ) {
      setLoader(false);
      message.error(
        "Number of Years in practice length should not be more than 100 letters"
      );
      return;
    }

    // Validation Check - facebook
    if (finalData?.facebook && finalData?.facebook?.length > 100) {
      setLoader(false);
      message.error("Facebook URL length should not be more than 100 letters");
      return;
    }

    // Validation Check - instagram
    if (finalData?.instagram && finalData?.instagram?.length > 100) {
      setLoader(false);
      message.error("instagram URL length should not be more than 100 letters");
      return;
    }

    // Validation Check - firstName
    if (finalData?.firstName && finalData?.firstName?.length > 30) {
      setLoader(false);
      message.error("FIRST NAME length should not be more than 30 letters");
      return;
    } else if (checkContainsEmoji(finalData?.firstName)) {
      setLoader(false);
      message.error("First Name should not contain any emoji");
      return;
    }

    // Validation Check - lastName
    if (finalData?.lastName && finalData?.lastName?.length > 30) {
      setLoader(false);
      message.error("LAST NAME length should not be more than 30 letters");
      return;
    } else if (checkContainsEmoji(finalData?.lastName)) {
      setLoader(false);
      message.error("Last Name should not contain any emoji");
      return;
    }

    // Validation Check - location
    if (finalData?.location && finalData?.location?.length > 256) {
      setLoader(false);
      message.error(
        "Location(City) length should not be more than 256 letters"
      );
      return;
    }

    // Validation Check - phoneno
    if (finalData?.phoneno && finalData?.phoneno?.length > 30) {
      setLoader(false);
      message.error("PHONE NO length should not be more than 30 letters");
      return;
    }

    // Validation Check - aboutme
    if (finalData?.aboutme && finalData?.aboutme?.length > 1500) {
      setLoader(false);
      message.error("ABOUT ME length should not be more than 1500 letters");
      return;
    }

    // Validation Check - title
    if (finalData?.title && finalData?.title?.length > 100) {
      setLoader(false);
      message.error("Title length should not be more than 100 letters");
      return;
    }

    const finalFormData = new FormData();

    // checking if the image is selected, then add it to the form data
    if (avatar) {
      finalFormData.append(
        "upload",
        file.blob,
        `${file.name}.${file.type.split("/")[1]}`
      );
    }

    for (let key in finalData) {
      finalFormData.append(key, finalData[key]);
    }

    try {
      const token = await getIdTokenClaims();
      await updateMemberAndUserSettingsAPI(
        finalFormData,
        token.__raw
      );
      message.info("Profile Information updated successfully");
      await getMemberAccountInfo(memberUserId);
      setLoader(false);
      onClose();
    } catch (error) {
      console.log(error);
      message.error(
        "Something went wrong, Failed to update Profile Information"
      );
      setLoader(false);
    }
  };

  const getCategoriesAPIHelper = async () => {
    try {
      const response = await getCategoriesAPI();
      setCategories(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllWellnessKeywordHelper = async () => {
    try {
      const response = await getWellnessKeywordsAPI();
      setWellnessKeywords(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllLanguagesHelper = async () => {
    try {
      const response = await getAllLanguagesAPI();
      setAllLanguages(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCategoriesAPIHelper();
    getAllWellnessKeywordHelper();
    getAllLanguagesHelper();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const existingQualifications = editData?.qualifications?.map((i) => {
      return { id: i.id };
    });
    setExistingTrainingAndCredentialsInputList(existingQualifications);

    const qualificationFormValue = editData?.qualifications?.reduce(
      (acc, curr) => {
        acc[`qualification-${curr.id}`] = curr.trainingandcredentials;
        return acc;
      },
      {}
    );

    if (editData) {
      form.setFieldsValue({
        ...editData,
        ...qualificationFormValue,
        languages: editData?.languages?.length
          ? editData?.languages?.map((i) => {
              return { children: i.name, value: i.id };
            })
          : [],
      });
      setSelectedWellnessKeyword(
        editData.wellnessKeywords.length
          ? editData.wellnessKeywords.map((i) => i.id)
          : []
      );
      setSelectedLocationData({
        lat: editData.latitude,
        long: editData.longitude,
        place: editData.location,
      });
    }
  }, [editData, form]);

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const customRequest = ({ file }) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const fileblob = new Blob([new Uint8Array(e.target.result)], {
        type: file.type,
      });

      setFile({ name: file.name, blob: fileblob, type: file.type });
    };

    getBase64(file, (imageUrl) => {
      setAvatar(imageUrl);
    });

    reader.readAsArrayBuffer(file);
  };

  return (
    <Modal
      destroyOnClose={true}
      footer={null}
      width={800}
      title="Edit Account Information"
      open={isOpen}
      onCancel={onClose}
      style={{ background: "#F5F9F9" }}
    >
      <Spin spinning={loader}>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={() =>
            message.error("Please fill all the mandatory fields!")
          }
        >
          <Form.Item
            style={{
              margin: "0 8px",
            }}
          >
            <Col
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
              }}
            >
              <p className={styles.formLabel}>
                First Name <span>*</span>
              </p>

              <Form.Item
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "First Name is required",
                  },
                ]}
              >
                <Input
                  disabled={true}
                  style={{
                    height: 60,
                  }}
                  placeholder="Please input your first name"
                />
              </Form.Item>
            </Col>

            <Col
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
                margin: "0 8px",
              }}
            >
              <p className={styles.formLabel}>
                Last Name <span>*</span>
              </p>
              <Form.Item
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Last Name is required",
                  },
                ]}
              >
                <Input
                  disabled={true}
                  style={{
                    height: 60,
                  }}
                  placeholder="Please input your last name"
                />
              </Form.Item>
            </Col>
          </Form.Item>

          <Col
            style={{
              display: "inline-block",
              width: "97%",
              margin: "0 8px",
            }}
          >
            <p className={styles.formLabel}>
              Email <span>*</span>
            </p>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Email is required",
                },
              ]}
            >
              <Input
                disabled={true}
                style={{
                  height: 60,
                }}
                placeholder="Please input your email"
              />
            </Form.Item>
          </Col>

          <Row gutter={[48, 16]}>
            <Col span={7} style={{ marginLeft: "10px" }}>
              <p className={styles.formLabel}>Image</p>
              <ImgCrop
                rotationSlider={true}
                showReset={true}
                modalTitle={"Crop Image"}
              >
                <Upload
                  name="avatar"
                  accept="image/*"
                  className={styles.acc_profile_box}
                  showUploadList={false}
                  customRequest={(data) => customRequest(data, "picture")}
                >
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
              </ImgCrop>
            </Col>
            <Col span={16}>
              {avatar ? (
                <Avatar
                  size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                  src={avatar}
                />
              ) : editData ? (
                <Avatar
                  size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                  src={
                    imageIsNotInS3(editData.image)
                      ? `${editData.image}`
                      : `${process.env.REACT_APP_BASE_URL}${editData.image}`
                  }
                />
              ) : null}
            </Col>
          </Row>
          <Col
            style={{
              display: "inline-block",
              width: "97%",
              margin: "0 8px",
            }}
          >
            <p className={styles.formLabel}>
              Location(City)<span>*</span>
            </p>
            <Form.Item name="location">
              <LocationSearch
                sendLocationInfo={setSelectedLocationData}
                defaultValue={
                  selectedLocationData?.place ? selectedLocationData?.place : ""
                }
              />
            </Form.Item>
          </Col>

          <Col
            style={{
              display: "inline-block",
              width: "97%",
              margin: "0 8px",
            }}
          >
            <p className={styles.formLabel}>
              Practitioner Profile Title <span>*</span> (One Line Description of
              your practitioner profile)
            </p>
            <Form.Item
              name="title"
              rules={[
                {
                  required: true,
                  message: "Title is required",
                },
              ]}
            >
              <Input.TextArea
                maxLength={100}
                showCount={true}
                rows={3}
                style={{
                  height: 60,
                }}
                placeholder="Please input a one line description of your practitioner profile"
              />
            </Form.Item>
          </Col>

          <Col
            style={{
              display: "inline-block",
              width: "97%",
              margin: "0 8px",
            }}
          >
            <p className={styles.formLabel}>
              About Me <span>*</span> (About you as a practitioner and your
              services)
            </p>
            <Form.Item
              name="aboutme"
              rules={[
                {
                  required: true,
                  message: "About me is required",
                },
              ]}
            >
              <Input.TextArea
                maxLength={1500}
                showCount={true}
                rows={12}
                placeholder="Please tell us about yourself and your services as a wellness practitioner"
              />
            </Form.Item>
          </Col>

          <Row
            style={{
              background: "#F5F9F9",
            }}
          >
            <p className={styles.formLabel}>
              Wellness practices (select up to 5 wellness practices that apply
              to your work from the list below) <span>*</span>
            </p>
            <Col
              style={{
                display: "inline-block",
                width: "45%",
                margin: "0 8px",
                background: "#F5F9F9",
              }}
            >
              <Form.Item name="wellnesskeywords">
                <Tree
                  checkable
                  showLine
                  style={{ width: 300, background: "#F5F9F9" }}
                  checkedKeys={selectedWellnessKeyword}
                  onCheck={(val) => setSelectedWellnessKeyword(val)}
                  treeData={
                    categories.length
                      ? categories.map((i) => ({
                          key: i.name,
                          title: i.name,
                          checkable: false,
                          children: i?.wellnessKeywords?.length
                            ? i?.wellnessKeywords?.map((j) => ({
                                key: j.id,
                                title: j.name,
                                selectable: true,
                              }))
                            : [],
                        }))
                      : []
                  }
                />
              </Form.Item>
            </Col>
            <Col
              style={{
                display: "inline-block",
                width: "45%",
                margin: "0 8px",
              }}
            >
              {selectedWellnessKeyword?.length > 0 ? (
                <div>
                  <h3>Selected Wellness Keywords (Max 5)</h3>
                  {selectedWellnessKeyword?.length
                    ? selectedWellnessKeyword?.map((i) => (
                        <p>
                          {wellnessKeywords?.find((j) => j.id === i)?.name}{" "}
                          &nbsp;{" "}
                          <CloseOutlined
                            onClick={(e) => {
                              e.preventDefault();
                              setSelectedWellnessKeyword(
                                selectedWellnessKeyword.filter((p) => p !== i)
                              );
                            }}
                            style={{ color: "red", cursor: "pointer" }}
                          />
                        </p>
                      ))
                    : null}
                </div>
              ) : (
                <p>No Wellness Keywords Selected</p>
              )}
            </Col>
          </Row>

          <Col
            style={{
              display: "inline-block",
              width: "97%",
              margin: "0 8px",
            }}
          >
            <p>
              <b>Note:</b> If your wellness practice is not listed above, please
              send a request with wellness practice name and description to{" "}
              <a href="mailto:support@wellmahealth.com">
                support@wellmahealth.com
              </a>
            </p>
          </Col>

          <Col
            style={{
              display: "inline-block",
              width: "97%",
              margin: "0 8px",
            }}
          >
            <p className={styles.formLabel}>
              Qualifications, Certifications, Education, Training etc.{" "}
              <span>*</span>
            </p>
            {existingTrainingAndCredentialsInputList?.map((i, index) => (
              <div key={i.id} className={styles.qualificationItem}>
                <Form.Item name={`qualification-${i.id}`}>
                  <Input.TextArea
                    maxLength={350}
                    showCount={true}
                    rows={1}
                    placeholder="Please input your Qualifications"
                  />
                </Form.Item>

                <DeleteOutlined
                  onClick={() => {
                    const list = [...existingTrainingAndCredentialsInputList];
                    list.splice(index, 1);
                    setExistingTrainingAndCredentialsInputList([...list]);
                  }}
                />
              </div>
            ))}
            {trainingAndCredentialsInputList?.map((i, index) => (
              <div
                key={`qualification-50000${i}`}
                className={styles.qualificationItem}
              >
                <Form.Item name={`qualification-50000${i}`}>
                  <Input.TextArea
                    maxLength={350}
                    showCount={true}
                    rows={1}
                    placeholder="Please input your Qualifications"
                  />
                </Form.Item>
                <DeleteOutlined
                  onClick={() => {
                    const list = [...trainingAndCredentialsInputList];
                    list.splice(index, 1);
                    setTrainingAndCredentialsInputList([...list]);
                  }}
                />
              </div>
            ))}
            <Button
              onClick={(e) => {
                e.preventDefault();
                setTrainingAndCredentialsInputList([
                  ...trainingAndCredentialsInputList,
                  trainingAndCredentialsInputList.length === 0
                    ? 1
                    : trainingAndCredentialsInputList.length === 1
                    ? 2
                    : trainingAndCredentialsInputList[
                        trainingAndCredentialsInputList.length - 1
                      ] + 1,
                ]);
              }}
              style={{ marginBottom: "24px" }}
            >
              Add Qualification
            </Button>
          </Col>

          <Col
            style={{
              display: "inline-block",
              width: "97%",
              margin: "0 8px",
            }}
          >
            <p className={styles.formLabel}>Choose Your Language Preferences</p>
            <Form.Item name="languages">
              <Select
                placeholder="Please select languages"
                allowClear
                mode="multiple"
                optionFilterProp="children"
                filterOption={(input, option) => {
                  console.log("option > ", option);
                  return (
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
              >
                {allLanguages?.map((i) => (
                  <Option value={i?.id}>{i?.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          {/* This below filed is removed */}
          {/* 
          <Col
            style={{
              display: "inline-block",
              width: "97%",
              margin: "0 8px",
            }}
          >
            <p className={styles.formLabel}>
              Do you offer services Online / In Person / Both* <span>*</span>
            </p>
            <Form.Item
              name="virtualsessions"
              rules={[
                {
                  required: true,
                  message:
                    "Do you offer services Online / In Person / Both is required",
                },
              ]}
            >
              <Select
                placeholder="Please select Online / In Person / Both"
                allowClear
              >
                <Option value="y">Online</Option>
                <Option value="n">In Person</Option>
                <Option value="both">Both</Option>
              </Select>
            </Form.Item>
          </Col> */}

          <Col
            style={{
              display: "inline-block",
              width: "97%",
              margin: "0 8px",
            }}
          >
            <p className={styles.formLabel}>Number of Years in practice</p>
            <Form.Item name="numberOfYearsInPractice">
              <Input.TextArea
                maxLength={100}
                showCount={true}
                rows={3}
                style={{
                  height: 60,
                }}
                placeholder="Please enter your Number of Years in practice"
              />
            </Form.Item>
          </Col>

          <Col
            style={{
              display: "inline-block",
              width: "97%",
              margin: "0 8px",
            }}
          >
            <p className={styles.formLabel}>Phone No</p>
            <Form.Item name="phoneno">
              <PhoneInput
                containerClass="phoneContainer"
                buttonClass="phoneInputButton"
                inputClass="phoneInput"
                country={"us"}
                enableSearch={true}
              />
            </Form.Item>
          </Col>

          <Col
            style={{
              display: "inline-block",
              width: "97%",
              margin: "0 8px",
            }}
          >
            <p className={styles.formLabel}>WEBSITE</p>
            <Form.Item name="website">
              <Input
                style={{
                  height: 60,
                }}
                placeholder="Please input your Website"
              />
            </Form.Item>
          </Col>

          <Col
            style={{
              display: "inline-block",
              width: "97%",
              margin: "0 8px",
            }}
          >
            <p className={styles.formLabel}>Facebook URL </p>
            <Form.Item name="facebook">
              <Input.TextArea
                maxLength={100}
                showCount={true}
                rows={3}
                style={{
                  height: 60,
                }}
                placeholder="Please input your Facebook URL"
              />
            </Form.Item>
          </Col>

          <Col
            style={{
              display: "inline-block",
              width: "97%",
              margin: "0 8px",
            }}
          >
            <p className={styles.formLabel}>Instagram URL </p>
            <Form.Item name="instagram">
              <Input.TextArea
                maxLength={100}
                showCount={true}
                rows={3}
                style={{
                  height: 60,
                }}
                placeholder="Please input your Instagram URL"
              />
            </Form.Item>
          </Col>
          {/* 
                <Col
                  style={{
                    display: "inline-block",
                    width: "97%",
                    margin: "0 8px",
                  }}
                >
                  <p className={styles.formLabel}>Twitter URL </p>
                  <Form.Item name="twitter">
                    <Input.TextArea
                      maxLength={100}
                      showCount={true}
                      rows={3}
                      style={{
                        height: 60,
                      }}
                      placeholder="Please input your Twitter URL"
                    />
                  </Form.Item>
                </Col> */}

          {/* <Col
                  style={{
                    display: "inline-block",
                    width: "97%",
                    margin: "0 8px",
                  }}
                >
                  <p className={styles.formLabel}>Physical Address </p>
                  <Form.Item name="physicaladdress">
                    <Input.TextArea
                      maxLength={100}
                      showCount={true}
                      rows={3}
                      style={{
                        height: 60,
                      }}
                      placeholder="Please input your Physical Address"
                    />
                  </Form.Item>
                </Col> */}

          <Form.Item>
            <button className={styles.formSubmitButton} htmlType="submit">
              Update
            </button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default MemberAccountInfoEditModal;
