import { useCallback, useEffect, useState } from "react";
import MemberAccountInfo from "../../components/MemberAccountInfo";
import MemberEventsInfo from "../../components/MemberEventsInfo";
import MemberServicesInfo from "../../components/MemberServicesInfo";
import MemberContent from "../../components/MemberContent";
import PageWrapper from "../../components/PageWrapper";
import MemberAccountAndTrainingInfo from "../../components/MemberAccountAndTrainingInfo";

import styles from "./Member.module.css";

import { useParams } from "react-router-dom";
import { Alert, Spin } from "antd";
import { useMemberStore } from "../../store/memberStore";
import MemberReviews from "../../components/MemberReviews";

const Member = () => {
  let { memberUserId } = useParams();
  const [loading, setLoading] = useState(false);

  const getMemberAccountInfo = useMemberStore(
    (state) => state.getMemberAccountInfo
  );
  const memberAccountInfo = useMemberStore((state) => state.memberAccountInfo);


  const getMemberDetails = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getMemberAccountInfo(memberUserId);

      console.log("response : ", response);
    } catch (error) {
      console.log("error :>> ", error);
    } finally {
      setLoading(false);
    }
  }, [memberUserId, getMemberAccountInfo]);

  useEffect(() => {
    getMemberDetails();
  }, [getMemberDetails]);

  return (
    <Spin spinning={loading}>
      <PageWrapper>
        <div className={styles.wrapper}>
          <div className={styles.leftSideWrapper}>
            <MemberAccountInfo />
            <MemberReviews />
          </div>
          <div className={styles.rightSideWrapper}>
            <MemberAccountAndTrainingInfo />
            {memberAccountInfo?.accountstatus === "inactive" ? (
              <div className={styles.alertForInactiveUserWrapper}>
                <Alert
                  message="Account activation In Progress"
                  description="Your account is under verification process, you can add/update the services and events once your account is activated by the admin."
                  type="info"
                  showIcon
                />
              </div>
            ) : (
              <>
                <MemberServicesInfo />
                <MemberEventsInfo />
                <MemberContent />
              </>
            )}
          </div>
        </div>
      </PageWrapper>
    </Spin>
  );
};

export default Member;
