import styles from "./MemberServicesInfo.module.css";
import { Collapse, Divider, Empty, message } from "antd";
import MemberServiceAddEditModal from "../MemberServiceAddEditModal";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMemberStore } from "../../store/memberStore";
import {
  deleteMemberServiceAPI,
  getMemberServicesAPI,
} from "../../api/memberServicesApis";
import DeleteConformationModal from "../DeleteConformationModal";
import { useAuth0 } from "@auth0/auth0-react";
import { imageIsNotInS3 } from "../../utils";

import { PlusCircleFilled, EditFilled, DeleteFilled } from "@ant-design/icons";
import currencyData from "../../constants/currency.json";
import ServiceBookingModal from "../ServiceBookingModal";
import { useRestrictionStore } from "../../store/restrictions";
import RestrictionPopUp from "../RestrictionPopup";

const { Panel } = Collapse;

const MemberServicesInfo = () => {
  let { memberUserId } = useParams();
  const { isAuthenticated, getIdTokenClaims, user, loginWithRedirect } =
    useAuth0();

  const memberAccountInfo = useMemberStore((state) => state.memberAccountInfo);
  const restrictionsData = useRestrictionStore(
    (state) => state.restrictionsData
  );

  const [serviceAddEditModalState, setServiceAddEditModalState] =
    useState(false);
  const [serviceDeleteModalState, setServiceDeleteModalState] = useState(false);
  const [serviceBookingModalState, setServiceBookingModalState] =
    useState(false);
  const [services, setServices] = useState([]);
  const [showRestrictionPopUp, setShowRestrictionPopUp] = useState(false);
  const [selectedServiceForEditOrDelete, setSelectedServiceForEditOrDelete] =
    useState();

  const changeServiceDeleteModalState = () => {
    setServiceDeleteModalState(!serviceDeleteModalState);
  };

  const changeServiceAddEditModalState = () => {
    setServiceAddEditModalState(!serviceAddEditModalState);
  };

  const openServiceBookingModal = (data) => {
    setServiceBookingModalState(true);
    setSelectedServiceForEditOrDelete(data);
  };

  const closeServiceBookingModal = () => {
    setServiceBookingModalState(false);
    setSelectedServiceForEditOrDelete();
  };

  const deleteMemberServicesHelper = async () => {
    try {
      const token = await getIdTokenClaims();
      await deleteMemberServiceAPI(
        selectedServiceForEditOrDelete.id,
        token.__raw
      );
      changeServiceDeleteModalState();
      getMemberServicesHelper();
      setSelectedServiceForEditOrDelete();

      message.info("Service Deleted Successfully");
    } catch (error) {
      console.log(error);
      message.error("Something went wrong, Unable to delete the Service");
    }
  };

  const getMemberServicesHelper = useCallback(async () => {
    try {
      const response = await getMemberServicesAPI(memberUserId);
      setServices(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }, [memberUserId]);

  useEffect(() => {
    getMemberServicesHelper();
  }, [getMemberServicesHelper]);

  return (
    <div className={styles.wrapper}>
      <Collapse
        style={{ width: "100%", margin: "24px 0" }}
        expandIconPosition={"end"}
        defaultActiveKey={["1"]}
        size="large"
      >
        <Panel header="Services Section" key="1">
          <div className={styles.serviceHeadingSection}>
            <div className={styles.serviceHeadingText}>Services</div>
            {isAuthenticated &&
            memberAccountInfo.username === memberUserId &&
            memberAccountInfo?.user_authId === user?.sub ? (
              <div
                className={styles.serviceHeadingAddServiceButton}
                onClick={() =>
                  restrictionsData?.length &&
                  restrictionsData?.find(
                    (i) =>
                      i.restrictionName === "max-service" &&
                      i.status === "active"
                  ) && memberAccountInfo?.role === "basic"
                    ? Number(restrictionsData?.find(
                        (i) =>
                          i.restrictionName === "max-service" &&
                          i.status === "active"
                      )?.value) > services?.length
                      ? changeServiceAddEditModalState()
                      : setShowRestrictionPopUp(true)
                    : changeServiceAddEditModalState()
                }
              >
                <PlusCircleFilled
                  className={styles.serviceHeadingAddServiceButtonIcon}
                />
                <p className={styles.serviceHeadingAddServiceButtonText}>
                  Add Service
                </p>
              </div>
            ) : null}
          </div>
          <div className={styles.serviceList}>
            {services?.length ? (
              services
                ?.filter((i) => i)
                ?.map((i) => (
                  <Fragment key={i.id}>
                    <div className={styles.serviceListItem}>
                      <div className={styles.serviceListItemImageSection}>
                        <img
                          src={
                            imageIsNotInS3(i.image)
                              ? `${i.image}`
                              : `${process.env.REACT_APP_BASE_URL}${i.image}`
                          }
                          className={styles.serviceListItemImage}
                          alt="Service"
                        />

                        {isAuthenticated &&
                        memberAccountInfo.username === memberUserId &&
                        memberAccountInfo?.user_authId === user?.sub ? (
                          <div
                            className={styles.serviceListItemEditButton}
                            onClick={() => {
                              changeServiceAddEditModalState();
                              setSelectedServiceForEditOrDelete(i);
                            }}
                          >
                            <EditFilled />
                          </div>
                        ) : null}
                        {isAuthenticated &&
                        memberAccountInfo.username === memberUserId &&
                        memberAccountInfo?.user_authId === user?.sub ? (
                          <div
                            className={styles.serviceListItemDeleteButton}
                            onClick={() => {
                              setSelectedServiceForEditOrDelete(i);
                              changeServiceDeleteModalState();
                            }}
                          >
                            <DeleteFilled />
                          </div>
                        ) : null}
                      </div>
                      <div className={styles.serviceListItemInfoSection}>
                        <div
                          className={styles.serviceListItemInfoSectionTopLine}
                        >
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <p className={styles.serviceListItemName}>
                              {i?.name}
                            </p>
                            {isAuthenticated &&
                            memberAccountInfo.username === memberUserId &&
                            memberAccountInfo?.user_authId ===
                              user?.sub ? null : (
                              <button
                                className={styles.bookingButton}
                                onClick={() =>
                                  isAuthenticated &&
                                  memberAccountInfo?.user_authId !== user?.sub
                                    ? openServiceBookingModal(i)
                                    : loginWithRedirect({
                                        appState: {
                                          returnTo: window.location.pathname,
                                        },
                                      })
                                }
                              >
                                Book Appointment
                              </button>
                            )}
                          </div>
                        </div>
                        <p className={styles.serviceListItemTimeText}>
                          <b>Duration:</b> &nbsp; &nbsp;
                          {i.duration
                            ? i.duration.replace(":", ".").split(".")[0] > 0
                              ? `${i.duration.replace(":", ".")} hr`
                              : `${
                                  i.duration.replace(":", ".").split(".")[1]
                                } mins`
                            : null}
                        </p>
                        <div className={styles.serviceListItemPrice}>
                          <b>Price:</b> &nbsp; &nbsp;
                          {i.feetype === "free"
                            ? "FREE"
                            : i.feetype === "fixed"
                            ? `${i.feepersession}
                              ${
                                i?.currency
                                  ? currencyData.find(
                                      (currency) =>
                                        currency?.currency === i?.currency
                                    )?.currency
                                  : ""
                              }`
                            : `${i.slidingscalemin} ${
                                i?.currency
                                  ? currencyData.find(
                                      (currency) =>
                                        currency?.currency === i?.currency
                                    )?.currency
                                  : ""
                              } - ${i.slidingscalemax}  ${
                                i?.currency
                                  ? currencyData.find(
                                      (currency) =>
                                        currency?.currency === i?.currency
                                    )?.currency
                                  : ""
                              }`}
                        </div>
                        <div
                          className={
                            styles.serviceListItemInfoSectionDescription
                          }
                        >
                          {i.description}
                        </div>
                        <div
                          className={
                            styles.serviceListItemInfoSectionWellnessKeywords
                          }
                        >
                          {i.wellnessKeywords.length
                            ? i.wellnessKeywords
                                ?.filter((i) => i)
                                .map((i) => (
                                  <div
                                    key={i.id}
                                    className={
                                      styles.serviceListItemInfoSectionWellnessKeywordItem
                                    }
                                  >
                                    {i?.name}
                                  </div>
                                ))
                            : null}
                        </div>
                      </div>
                    </div>
                    <Divider />
                  </Fragment>
                ))
            ) : (
              <Empty style={{ marginTop: "100px" }} />
            )}
          </div>
        </Panel>
      </Collapse>
      {serviceAddEditModalState && (
        <MemberServiceAddEditModal
          isOpen={serviceAddEditModalState}
          editData={selectedServiceForEditOrDelete}
          onClose={() => {
            changeServiceAddEditModalState();
            setSelectedServiceForEditOrDelete();
            getMemberServicesHelper();
          }}
        />
      )}
      {serviceDeleteModalState && (
        <DeleteConformationModal
          title={"Delete Service"}
          isOpen={serviceDeleteModalState}
          onClose={changeServiceDeleteModalState}
          content={
            "Are you sure you want to delete this service. once deleted it can not be recovered"
          }
          onConform={deleteMemberServicesHelper}
        />
      )}

      {serviceBookingModalState ? (
        <ServiceBookingModal
          isOpen={serviceBookingModalState}
          onClose={closeServiceBookingModal}
          serviceData={selectedServiceForEditOrDelete}
        />
      ) : null}

      {showRestrictionPopUp ? (
        <RestrictionPopUp
          isOpen={showRestrictionPopUp}
          onClose={() => setShowRestrictionPopUp(false)}
        />
      ) : null}
    </div>
  );
};

export default MemberServicesInfo;
