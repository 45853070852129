import axios from "../config/axiosConfig";

export const getReviewsAPI = async ({ typeId, type }) => {
  try {
    const response = await axios.get(
      `/api/getReviewsByTypeId?typeId=${typeId}&type=${type}&sort=DESC`
    );
    return response;
  } catch (error) {
    throw Error(error);
  }
};

export const createReviewsAPI = async ({ data, token }) => {
  try {
    const response = await axios.post(`/api/addReview`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw Error(error);
  }
};

export const updateReviewsAPI = async ({ data, token }) => {
  try {
    const response = await axios.patch(`/api/updateReview`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw Error(error);
  }
};
