import { useEffect, useState } from "react";
import {
  getAppointmentByUserAPI,
  updateAppointmentAPI,
} from "../../api/appointmentApis";
import { useAuth0 } from "@auth0/auth0-react";
import PageWrapper from "../../components/PageWrapper";
import styles from "./Appointments.module.css";
import { Empty, message, Popover, Spin } from "antd";
import {
  calculatePercentage,
  capitalizeWords,
  convertPaymentStatus,
} from "../../utils";
import currencyData from "../../constants/currency.json";
import { useNavigate } from "react-router-dom";
import { getMemberDetailsWithUserAuthIdAPI } from "../../api/memberApis";
import axios from "axios";
import AppointmentUpdateModal from "../../components/AppointmentUpdateModal";
import { useRestrictionStore } from "../../store/restrictions";
import { InfoCircleOutlined } from "@ant-design/icons";

const dayjs = require("dayjs");
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");
const advancedFormat = require("dayjs/plugin/advancedFormat");

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const Appointments = () => {
  const { getIdTokenClaims, user } = useAuth0();
  const navigate = useNavigate();

  const restrictionsData = useRestrictionStore(
    (state) => state.restrictionsData
  );

  const [appointmentsData, setAppointmentsData] = useState([]);
  const [selectedAppointmentsData, setSelectedAppointmentsData] = useState();
  const [rescheduleModal, setRescheduleModal] = useState(false);
  const [loader, setLoader] = useState(false);

  const getAppointmentsByUser = async () => {
    try {
      setLoader(true);
      const token = await getIdTokenClaims();
      const response = await getAppointmentByUserAPI(token.__raw);

      setAppointmentsData(response.data.rows);
      setLoader(false);
    } catch (error) {
      console.log("error :>> ", error);
      setLoader(false);
    }
  };

  const updateAppointment = async (data) => {
    try {
      setLoader(true);
      const token = await getIdTokenClaims();
      await updateAppointmentAPI(token.__raw, data);
      message.success("Appointment updated successfully");
      getAppointmentsByUser();
    } catch (error) {
      console.log("error :>> ", error);
      setLoader(false);
    }
  };

  useEffect(() => {
    getAppointmentsByUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createGoogleAppointmentAPICall = async (
    appointmentData,
    access_token
  ) => {
    const url =
      "https://www.googleapis.com/calendar/v3/calendars/primary/events";

    const headers = {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    };

    const event = {
      summary: appointmentData?.name,
      start: {
        dateTime: dayjs(appointmentData?.startdatetime).utc().toISOString(),
      },
      end: {
        dateTime: dayjs(appointmentData?.enddatetime).utc().toISOString(),
      },
      attendees: [
        {
          email: user.email,
        },
      ],
    };

    try {
      const response = await axios.post(url, event, { headers });

      if (response.status === 200 || response.status === 201) {
        console.log("Event created successfully", response.data);
      } else {
        console.log(
          `Failed to create event: ${response.status} - ${response.data}`
        );
      }
    } catch (error) {
      console.error("Error creating event:", error);
    }
  };

  const createGoogleAppointment = async (appointmentData) => {
    const memberData = await getMemberDetailsWithUserAuthIdAPI(
      appointmentData?.memberAuthId
    );

    console.log("memberData :>> ", memberData);

    const isTokenExpired = () => {
      const currentTime = new Date();
      const tokenExpirationTime = new Date(
        memberData?.googleCredentials?.createdAt * 1000 +
          memberData?.googleCredentials?.expiresIn * 1000
      );

      if (currentTime > tokenExpirationTime) {
        return true;
      } else {
        return false;
      }
    };

    if (memberData?.data?.data?.googleCredentials?.access_token) {
      if (isTokenExpired()) {
        console.log("Token expired");
        const refreshToken =
          memberData?.data?.data?.googleCredentials?.refresh_token;
        if (refreshToken && refreshToken.length > 0) {
          try {
            const response = await axios.post(
              "https://oauth2.googleapis.com/token",
              new URLSearchParams({
                client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
                grant_type: "refresh_token",
                refresh_token:
                  memberData?.data?.data?.googleCredentials?.refresh_token,
              }),
              {
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                },
              }
            );

            if (response.status === 200) {
              createGoogleAppointmentAPICall(
                appointmentData,
                response.data.access_token
              );
            } else {
              console.error(`Error: ${JSON.stringify(response.data)}`);
              // disconnectGoogleCalendar(userAuthId, true);
            }
          } catch (error) {
            console.error(`Error: ${error}`);
            // disconnectGoogleCalendar(userAuthId, true);
          }
        } else {
          console.log("No refresh token");
        }
      } else {
        console.log("Token not expired");
        createGoogleAppointmentAPICall(
          appointmentData,
          memberData?.data?.data?.googleCredentials?.access_token
        );
      }
    } else {
      console.log("no access token");
    }
  };

  const AcceptButton = ({ data }) => {
    return (
      <button
        className={styles.acceptButton}
        onClick={() => {
          updateAppointment({
            ...data,
            appointmentId: data.id,
            status: "booked",
          });

          createGoogleAppointment(data);
        }}
      >
        Accept
      </button>
    );
  };
  const RescheduleButton = ({ data }) => {
    return (
      <button
        className={styles.rescheduleButton}
        onClick={() => {
          // updateAppointment({
          //   ...data,
          //   appointmentId: data.id,
          //   status: "pendingPractitionerConfirm",
          // })
          setSelectedAppointmentsData(data);
          setRescheduleModal(true);
        }}
      >
        Reschedule
      </button>
    );
  };
  const CancelButton = ({ data }) => {
    return (
      <button
        className={styles.cancelButton}
        onClick={() =>
          updateAppointment({
            ...data,
            appointmentId: data.id,
            status: "cancelled",
          })
        }
      >
        Cancel
      </button>
    );
  };
  const CompleteButton = ({ data }) => {
    return (
      <button
        className={styles.completeButton}
        onClick={() =>
          updateAppointment({
            ...data,
            appointmentId: data.id,
            status: "complete",
          })
        }
      >
        Complete
      </button>
    );
  };
  const PaymentButton = ({ data }) => {
    return (
      <button
        className={styles.paymentButton}
        onClick={() => navigate(`/payment/${data?.paymentId}`)}
      >
        Make Payment
      </button>
    );
  };

  return (
    <Spin spinning={loader}>
      <PageWrapper>
        {" "}
        <div className={styles.wrapper}>
          {appointmentsData?.length ? (
            appointmentsData.map((data) => {
              let wellmaFee =
                data.paymentStatus === "successful"
                  ? Number(data.wellmaFee)
                  : Number(
                      restrictionsData?.find(
                        (i) =>
                          i.restrictionName === "Wellma-Fee" &&
                          i.status === "active"
                      )?.value
                    )
                  ? Number(
                      restrictionsData?.find(
                        (i) =>
                          i.restrictionName === "Wellma-Fee" &&
                          i.status === "active"
                      )?.value
                    )
                  : 0;

              let processingFee =
                data.paymentStatus === "successful"
                  ? Number(data.paymentFee)
                  : Number(
                      restrictionsData?.find(
                        (i) =>
                          i.restrictionName === "Payment-Fee" &&
                          i.status === "active"
                      )?.value
                    )
                  ? Number(
                      restrictionsData?.find(
                        (i) =>
                          i.restrictionName === "Payment-Fee" &&
                          i.status === "active"
                      )?.value
                    )
                  : 0;

              return (
                <div key={data.id} className={styles.appointmentCard}>
                  <p>
                    <b>Name:</b> {data.name}
                  </p>
                  <p>
                    <b>Appointment Status:</b>{" "}
                    {data.status === "booked"
                      ? "Your appointment has been confirmed and is now booked."
                      : data.status === "cancelled"
                      ? "Your appointment has been canceled."
                      : data.status === "rejected"
                      ? "Your appointment request has been rejected."
                      : data.status === "pendingPractitionerConfirm"
                      ? "Your appointment is pending confirmation from the practitioner."
                      : data.status === "pendingUserConfirm"
                      ? "Your appointment is pending your confirmation."
                      : data.status === "complete"
                      ? "Your appointment has been completed."
                      : "Your appointment is pending confirmation from the practitioner."}
                  </p>
                  <p>
                    <b>Type:</b> {capitalizeWords(data.type)}
                  </p>
                  <p>
                    <b>Date & Time:</b>{" "}
                    {dayjs.utc(data.startdatetime).local().format("YYYY-MM-DD")}{" "}
                    {dayjs.utc(data.startdatetime).local().format("hh:mm A")}
                  </p>
                  <p>
                    <b>
                      {data.feetype === "fixed" ? "Total Fee" : "Fee range"}:
                    </b>{" "}
                    {data.feetype === "free" ? (
                      "FREE"
                    ) : data.feetype === "fixed" ? (
                      <>
                        {`${
                          Number(data.feepersession) +
                          Number(
                            processingFee
                              ? calculatePercentage(
                                  Number(data.feepersession),
                                  processingFee
                                )
                              : 0
                          ) +
                          Number(
                            wellmaFee
                              ? calculatePercentage(
                                  Number(data.feepersession),
                                  wellmaFee
                                )
                              : 0
                          )
                        }
                      ${
                        data?.currency
                          ? currencyData.find(
                              (currency) =>
                                currency?.currency === data?.currency
                            )?.currency
                          : ""
                      }`}
                        <Popover
                          content={
                            <div>
                              <div className={styles.paymentSectionItemDiv}>
                                <p>Service Fee:</p>{" "}
                                <p className={styles.feeAndCurrency}>
                                  {`${Number(data.feepersession)} ${
                                    data?.currency
                                      ? currencyData.find(
                                          (currency) =>
                                            currency?.currency ===
                                            data?.currency
                                        )?.currency
                                      : ""
                                  }`}
                                </p>
                              </div>
                              {processingFee ? (
                                <div className={styles.paymentSectionItemDiv}>
                                  <p>Processing Fee:</p>{" "}
                                  <p className={styles.feeAndCurrency}>
                                    {`${Number(
                                      processingFee
                                        ? calculatePercentage(
                                            data.feepersession,
                                            processingFee
                                          )
                                        : 0
                                    )} ${
                                      data?.currency
                                        ? currencyData.find(
                                            (currency) =>
                                              currency?.currency ===
                                              data?.currency
                                          )?.currency
                                        : ""
                                    }`}
                                  </p>
                                </div>
                              ) : null}

                              {wellmaFee ? (
                                <div className={styles.paymentSectionItemDiv}>
                                  <p>Wellma Fee:</p>{" "}
                                  <p className={styles.feeAndCurrency}>
                                    {`${Number(
                                      wellmaFee
                                        ? calculatePercentage(
                                            Number(data.feepersession),
                                            wellmaFee
                                          )
                                        : 0
                                    )} ${
                                      data?.currency
                                        ? currencyData.find(
                                            (currency) =>
                                              currency?.currency ===
                                              data?.currency
                                          )?.currency
                                        : ""
                                    }`}
                                  </p>
                                </div>
                              ) : null}

                              <div className={styles.paymentSectionItemDiv}>
                                <p>Total Fee:</p>{" "}
                                <p className={styles.feeAndCurrency}>
                                  {`${(
                                    Number(data.feepersession) +
                                    Number(
                                      processingFee
                                        ? calculatePercentage(
                                            data.feepersession,
                                            processingFee
                                          )
                                        : 0
                                    ) +
                                    Number(
                                      wellmaFee
                                        ? calculatePercentage(
                                            data.feepersession,
                                            wellmaFee
                                          )
                                        : 0
                                    )
                                  ).toFixed(2)}
                              ${
                                data?.currency
                                  ? currencyData.find(
                                      (currency) =>
                                        currency?.currency === data?.currency
                                    )?.currency
                                  : ""
                              }`}
                                </p>
                              </div>
                            </div>
                          }
                          title="Fee Breakdown"
                        >
                          <InfoCircleOutlined
                            style={{
                              marginLeft: "8px",
                              color: "#1677ff",
                              cursor: "pointer",
                            }}
                          />
                        </Popover>
                      </>
                    ) : (
                      `${data.slidingscalemin} ${
                        data?.currency
                          ? currencyData.find(
                              (currency) =>
                                currency?.currency === data?.currency
                            )?.currency
                          : ""
                      } - ${data.slidingscalemax}  ${
                        data?.currency
                          ? currencyData.find(
                              (currency) =>
                                currency?.currency === data?.currency
                            )?.currency
                          : ""
                      }`
                    )}
                  </p>

                  {data.feetype === "sliding scale" && data.feepersession ? (
                    <p>
                      <b>Total Fee:</b>{" "}
                      {`${(
                        Number(data.feepersession) +
                        Number(
                          processingFee
                            ? calculatePercentage(
                                data.feepersession,
                                processingFee
                              )
                            : 0
                        ) +
                        Number(
                          wellmaFee
                            ? calculatePercentage(data.feepersession, wellmaFee)
                            : 0
                        )
                      ).toFixed(2)}
                              ${
                                data?.currency
                                  ? currencyData.find(
                                      (currency) =>
                                        currency?.currency === data?.currency
                                    )?.currency
                                  : ""
                              }`}
                      <Popover
                        content={
                          <div>
                            <div className={styles.paymentSectionItemDiv}>
                              <p>Service Fee:</p>{" "}
                              <p className={styles.feeAndCurrency}>
                                {`${Number(data.feepersession)} ${
                                  data?.currency
                                    ? currencyData.find(
                                        (currency) =>
                                          currency?.currency === data?.currency
                                      )?.currency
                                    : ""
                                }`}
                              </p>
                            </div>
                            {processingFee ? (
                              <div className={styles.paymentSectionItemDiv}>
                                <p>Processing Fee:</p>{" "}
                                <p className={styles.feeAndCurrency}>
                                  {`${Number(
                                    processingFee
                                      ? calculatePercentage(
                                          data.feepersession,
                                          processingFee
                                        )
                                      : 0
                                  )} ${
                                    data?.currency
                                      ? currencyData.find(
                                          (currency) =>
                                            currency?.currency ===
                                            data?.currency
                                        )?.currency
                                      : ""
                                  }`}
                                </p>
                              </div>
                            ) : null}

                            {wellmaFee ? (
                              <div className={styles.paymentSectionItemDiv}>
                                <p>Wellma Fee:</p>{" "}
                                <p className={styles.feeAndCurrency}>
                                  {`${Number(
                                    wellmaFee
                                      ? calculatePercentage(
                                          data.feepersession,
                                          wellmaFee
                                        )
                                      : 0
                                  )} ${
                                    data?.currency
                                      ? currencyData.find(
                                          (currency) =>
                                            currency?.currency ===
                                            data?.currency
                                        )?.currency
                                      : ""
                                  }`}
                                </p>
                              </div>
                            ) : null}

                            <div className={styles.paymentSectionItemDiv}>
                              <p>Total Fee:</p>{" "}
                              <p className={styles.feeAndCurrency}>
                                {`${(
                                  Number(data.feepersession) +
                                  Number(
                                    processingFee
                                      ? calculatePercentage(
                                          data.feepersession,
                                          processingFee
                                        )
                                      : 0
                                  ) +
                                  Number(
                                    wellmaFee
                                      ? calculatePercentage(
                                          data.feepersession,
                                          wellmaFee
                                        )
                                      : 0
                                  )
                                ).toFixed(2)}
                              ${
                                data?.currency
                                  ? currencyData.find(
                                      (currency) =>
                                        currency?.currency === data?.currency
                                    )?.currency
                                  : ""
                              }`}
                              </p>
                            </div>
                          </div>
                        }
                        title="Fee Breakdown"
                      >
                        <InfoCircleOutlined
                          style={{
                            marginLeft: "8px",
                            color: "#1677ff",
                            cursor: "pointer",
                          }}
                        />
                      </Popover>
                    </p>
                  ) : null}
                  <p>
                    <b>Member:</b> {data.memberFirstName} {data.memberLastName}
                  </p>
                  {data.paymentStatus && data.memberRole === "premium" ? (
                    <p>
                      <b>Payment Status:</b>{" "}
                      {convertPaymentStatus(data.paymentStatus)}
                    </p>
                  ) : null}

                  {new Date() > new Date(data.startdatetime) &&
                  data.status === "booked" ? (
                    <>
                      {(data.feetype !== "free" &&
                        data.paymentStatus === "successful") ||
                      data.feetype === "free" ||
                      data.currency === "IDR" ? (
                        <CompleteButton data={data} />
                      ) : null}
                    </>
                  ) : data.status === "pendingPractitionerConfirm" ? (
                    <>
                      <CancelButton data={data} />
                    </>
                  ) : data.status === "pendingUserConfirm" ? (
                    <>
                      <CancelButton data={data} />
                      <RescheduleButton data={data} />
                      <AcceptButton data={data} />
                    </>
                  ) : data.status === "booked" &&
                    data.paymentStatus !== "successful" ? (
                    <>
                      <CancelButton data={data} />
                      <RescheduleButton data={data} />
                      {data.feetype !== "free" && (
                        <>
                          {data.paymentStatus !== "successful" &&
                          data.currency !== "IDR" &&
                          data.memberRole === "premium" ? (
                            data.paymentStatus === "paymentRequested" ||
                            data.paymentStatus === "processing" ||
                            data.paymentStatus === "failed" ||
                            data.paymentStatus === "cancelled" ? (
                              <PaymentButton data={data} />
                            ) : null
                          ) : data.currency === "IDR" ? (
                            <p className={styles.paymentInfoText}>
                              The payment needs to be made at the time of the
                              appointment to the practitioner.
                            </p>
                          ) : restrictionsData?.length &&
                            Number(
                              restrictionsData?.find(
                                (i) =>
                                  i.restrictionName === "Payments" &&
                                  i.status === "active"
                              )?.value
                            ) &&
                            data.memberRole === "basic" ? (
                            <PaymentButton data={data} />
                          ) : (
                            <p className={styles.paymentInfoText}>
                              The payment needs to be made at the time of the
                              appointment to the practitioner.
                            </p>
                          )}
                          {data.paymentStatus === "processing" &&
                          data.memberRole === "premium" ? (
                            <div>
                              <p className={styles.paymentInfoText}>
                                Payment is under process
                              </p>
                            </div>
                          ) : null}
                        </>
                      )}
                    </>
                  ) : data.status === "cancelled" ||
                    data.status === "completed" ||
                    data.status === "rejected" ? (
                    <></>
                  ) : null}
                </div>
              );
            })
          ) : (
            <Empty />
          )}
        </div>
        {rescheduleModal ? (
          <AppointmentUpdateModal
            isOpen={rescheduleModal}
            onClose={() => {
              setRescheduleModal(false);
              setSelectedAppointmentsData(null);
            }}
            appointmentData={selectedAppointmentsData}
            onUpdate={(startDateTime, endDateTime) => {
              updateAppointment({
                ...selectedAppointmentsData,
                appointmentId: selectedAppointmentsData.id,
                status: "pendingPractitionerConfirm",
                startdatetime: startDateTime,
                enddatetime: endDateTime,
              });
              setRescheduleModal(false);
              setSelectedAppointmentsData(null);
            }}
          />
        ) : null}
      </PageWrapper>
    </Spin>
  );
};

export default Appointments;
