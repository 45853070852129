import axios from "../config/axiosConfig";

export const getMemberEventsAPI = async (username) => {
  try {
    const response = await axios.get(
      `/api/getMemberEventsByUser?username=${username}`
    );
    return response;
  } catch (error) {
    throw Error(error);
  }
};

export const getPreviousMemberEventsAPI = async (username) => {
  try {
    const response = await axios.get(
      `/api/getPreviousMemberEventsByUser?username=${username}`
    );
    return response;
  } catch (error) {
    throw Error(error);
  }
};

export const createMemberEventsAPI = async (data, token) => {
  try {
    const response = await axios.post(`/api/createMemberEvents`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw Error(error);
  }
};

export const updateMemberEventsAPI = async (data, token) => {
  try {
    const response = await axios.put(`/api/updateMemberEvents`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw Error(error);
  }
};

export const deleteMemberEventAPI = async (eventId, token) => {
  try {
    const response = await axios.delete(
      `/api/deleteMemberEvents?eventId=${eventId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw Error(error);
  }
};

export const registerEventAPI = async (eventId, token) => {
  try {
    const response = await axios.post(
      `/api/updateRelationshipMetadata`,
      { interestType: "event", interestTypeId: eventId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw Error(error);
  }
};

export const getRelationshipMetadataByUserAPI = async (token) => {
  try {
    const response = await axios.get(
      `/api/getRelationshipMetadataByUser?interestType=event`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw Error(error);
  }
};
