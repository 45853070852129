import {
  Avatar,
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Tree,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import styles from "./MemberContentAddEditModal.module.css";
import { UploadOutlined, CloseOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import { imageIsNotInS3 } from "../../utils";
import { useAuth0 } from "@auth0/auth0-react";
import { stockImageUrls } from "../../constants";
import { getCategoriesAPI } from "../../api/categoryApis";
import { getWellnessKeywordsAPI } from "../../api/wellnessKeywordApis";
import {
  createMemberContentsAPI,
  updateMemberContentsAPI,
} from "../../api/memberContentsApis";
import { useParams } from "react-router-dom";
import { uploadFileToS3API } from "../../api/uploadFileApis";

const { Option } = Select;

const MemberContentAddEditModal = ({ isOpen, onClose, editData }) => {
  let { memberUserId } = useParams();

  const [contentType, setContentType] = useState("");
  const [file, setFile] = useState({});
  const [audioFile, setAudioFile] = useState({});
  const [videoFile, setVideoFile] = useState(null);
  const [avatar, setAvatar] = useState("");
  const [categories, setCategories] = useState([]);
  const [wellnessKeywords, setWellnessKeywords] = useState([]);
  const [selectedWellnessKeyword, setSelectedWellnessKeyword] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const [form] = Form.useForm();
  const { getIdTokenClaims } = useAuth0();

  const onFinish = async (data) => {
    console.log("data :>> ", data);
    setLoader(true);

    if (!selectedWellnessKeyword?.length) {
      message.error("Wellness practices is mandatory!");
      setLoader(false);
      return;
    }

    if (selectedWellnessKeyword?.length > 5) {
      message.error("Maximum wellness practice length is 5!");
      return;
    }

    const finalData = editData
      ? {
          ...data,
          wellnessKeywords: JSON.stringify(selectedWellnessKeyword),
          username: memberUserId,
          contentId: editData?.id,
        }
      : {
          ...data,
          wellnesskeywords: JSON.stringify(selectedWellnessKeyword),
          username: memberUserId,
          feetype: "free",
          // externalContentLink: "",
          externalContentType: contentType,
          externalContentPlatform: contentType === "video" ? "vimeo" : "s3",
        };

    // Validation Check - title
    if (finalData?.title && finalData?.title?.length > 200) {
      setLoader(false);
      message.error("Title length should not be more than 200 letters");
      return;
    }

    // Validation Check - description
    if (finalData?.description && finalData?.description?.length > 1500) {
      setLoader(false);
      message.error("Description length should not be more than 1500 letters");
      return;
    }

    const finalFormData = new FormData();

    const token = await getIdTokenClaims();

    // checking for video and audio upload
    if (!editData) {
      if (contentType === "video") {
        finalFormData.append("contentFile", videoFile.originFileObj);
      } else {
        // upload audio and thumbnail
        try {
          // audio
          const audioFormData = new FormData();
          audioFormData.append("upload", audioFile.originFileObj);
          const audioLink = await uploadFileToS3API(audioFormData, token.__raw);
          console.log("🚀 ~ onFinish ~ audioLink:", audioLink?.data?.fileUrl);
          finalFormData.append("audioUrl", audioLink?.data?.fileUrl);

          if (avatar || selectedImage) {
            // thumbnail
            const imageFormData = new FormData();
            if (selectedImage) {
              const response = await fetch(selectedImage);
              const imageBlob = await response.blob();
              imageFormData.append("upload", imageBlob);
            } else if (avatar) {
              imageFormData.append(
                "upload",
                file.blob,
                `${file.name}.${file.type.split("/")[1]}`
              );
            }

            const thumbnailLink = await uploadFileToS3API(
              imageFormData,
              token.__raw
            );
            console.log(
              "🚀 ~ onFinish ~ thumbnailLink:",
              thumbnailLink?.data?.fileUrl
            );
            finalFormData.append("imageUrl", thumbnailLink?.data?.fileUrl);
          }
        } catch (error) {
          console.log("🚀 ~ onFinish ~ error:", error);
        }
      }
    }

    if (!editData) {     
      console.log("Hello got it"); 
      for (let key in finalData) {
        finalFormData.append(key, finalData[key]);
      }
    }

    try {
      if (editData) {
        await updateMemberContentsAPI(finalData, token.__raw);
      } else {
        await createMemberContentsAPI(finalFormData, token.__raw);
      }
      message.info(`Content ${editData ? "Edited" : "Added"} Successfully`);
      setLoader(false);
      onClose();
    } catch (error) {
      console.log(error);
      message.error(
        `Something went wrong, Unable to ${
          editData ? "Edit" : "Add"
        } the Content`
      );
      setLoader(false);
    }
  };

  const getCategoriesAPIHelper = async () => {
    try {
      const response = await getCategoriesAPI();
      setCategories(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllWellnessKeywordHelper = async () => {
    try {
      const response = await getWellnessKeywordsAPI();
      setWellnessKeywords(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCategoriesAPIHelper();
    getAllWellnessKeywordHelper();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (editData) {
      form.setFieldsValue({
        ...editData,
        type: editData?.externalContentType,
      });
      setSelectedWellnessKeyword(
        editData.wellnessKeywords.length
          ? editData.wellnessKeywords.map((i) => i.id)
          : []
      );
    }
  }, [editData, form]);

  const handleFileRetrieval = () => {
    if (selectedImage !== null) {
      const selectedFile = new File([selectedImage], selectedImage, {
        type: "image/jpeg",
      });
      customRequest({ file: selectedFile, takeAvatar: selectedImage });
    }
  };

  useEffect(() => {
    if (selectedImage) {
      handleFileRetrieval();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedImage]);

  useEffect(() => {
    setFile({});
    setAvatar("");
    setAudioFile({});
    setVideoFile({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentType]);

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const customRequest = ({ file, takeAvatar = "" }) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const fileblob = new Blob([new Uint8Array(e.target.result)], {
        type: file.type,
      });

      setFile({ name: file.name, blob: fileblob, type: file.type });
    };
    if (takeAvatar) {
      setAvatar(takeAvatar);
    } else {
      getBase64(file, (imageUrl) => {
        setAvatar(imageUrl);
      });
    }
    reader.readAsArrayBuffer(file);
  };

  // handle video upload function
  const handleVideoUpload = (info) => {
    // Since only one file is allowed, pick the last one
    const latestFile = info.fileList.slice(-1)[0];
    console.log("🚀 ~ handleVideoUpload ~ latestFile:", latestFile);
    setVideoFile(latestFile ? latestFile : null);
  };

  // handle audio upload function
  const handleAudioUpload = (info) => {
    // Since only one file is allowed, pick the last one
    const latestFile = info.fileList.slice(-1)[0];
    console.log("🚀 ~ handleAudioUpload ~ latestFile:", latestFile);
    setAudioFile(latestFile ? latestFile : null);
  };

  return (
    <Modal
      title={editData ? "Content update form" : "Content add form"}
      open={isOpen}
      onCancel={onClose}
      destroyOnClose={true}
      footer={null}
      width={800}
    >
      <Spin spinning={loader}>
        <Form form={form}>
          <Col
            style={{
              display: "inline-block",
              width: "97%",
              margin: "0 8px",
            }}
          >
            <p className={styles.formLabel}>
              Title <span>*</span>
            </p>
            <Form.Item
              name="title"
              rules={[
                {
                  required: true,
                  message: "Title is required",
                },
              ]}
            >
              <Input
                style={{
                  height: 60,
                }}
                placeholder="Please provide Title"
              />
            </Form.Item>
          </Col>

          <Col
            style={{
              display: "inline-block",
              width: "97%",
              margin: "0 8px",
            }}
          >
            <p className={styles.formLabel}>
              Content <span>*</span>
            </p>
            <Form.Item
              name="type"
              rules={[
                {
                  required: true,
                  message: "Content type is required",
                },
              ]}
            >
              <Select
                placeholder="Select a option and change input text above"
                allowClear
                onChange={setContentType}
                disabled={editData}
              >
                <Option value="video">Video</Option>
                <Option value="audio">Audio</Option>
              </Select>
            </Form.Item>
          </Col>

          {contentType === "video" && !editData ? (
            <React.Fragment>
              <p className={styles.formLabel} style={{ paddingLeft: "10px" }}>
                Video <span>*</span>
              </p>
              <div className={styles.ImageUploadSection}>
                <div className={styles.avatarSectionWrapper}>
                  {videoFile?.name ? (
                    <span>
                      <b>File name:</b> {videoFile.name}
                    </span>
                  ) : null}
                </div>
                <div>
                  <Upload
                    name="avatar"
                    accept="video/*"
                    className={styles.acc_profile_box}
                    showUploadList={false}
                    onChange={handleVideoUpload}
                    beforeUpload={() => false}
                  >
                    <Button icon={<UploadOutlined />}>
                      Click to upload Video
                    </Button>
                  </Upload>
                </div>
              </div>
            </React.Fragment>
          ) : contentType === "audio" && !editData ? (
            <React.Fragment>
              <p className={styles.formLabel} style={{ paddingLeft: "10px" }}>
                Audio <span>*</span>
              </p>
              <div className={styles.ImageUploadSection}>
                <div className={styles.avatarSectionWrapper}>
                  {audioFile?.name ? (
                    <span>
                      <b>File name:</b> {audioFile.name}
                    </span>
                  ) : null}
                </div>
                <div>
                  <Upload
                    name="avatar"
                    accept="audio/*"
                    className={styles.acc_profile_box}
                    showUploadList={false}
                    onChange={handleAudioUpload}
                    beforeUpload={() => false}
                  >
                    <Button icon={<UploadOutlined />}>
                      Click to upload Audio
                    </Button>
                  </Upload>
                </div>
              </div>

              <p className={styles.formLabel} style={{ paddingLeft: "10px" }}>
                Thumbnail Image
              </p>
              <div className={styles.ImageUploadSection}>
                <div className={styles.avatarSectionWrapper}>
                  {avatar ? (
                    <Avatar
                      size={{
                        xs: 24,
                        sm: 32,
                        md: 40,
                        lg: 64,
                        xl: 80,
                        xxl: 100,
                      }}
                      src={avatar}
                    />
                  ) : editData ? (
                    <Avatar
                      size={{
                        xs: 24,
                        sm: 32,
                        md: 40,
                        lg: 64,
                        xl: 80,
                        xxl: 100,
                      }}
                      src={
                        imageIsNotInS3(editData.image)
                          ? `${editData.image}`
                          : `${process.env.REACT_APP_BASE_URL}${editData.image}`
                      }
                    />
                  ) : null}
                </div>
                <div>
                  <ImgCrop
                    rotationSlider={true}
                    showReset={true}
                    modalTitle={"Crop Image"}
                  >
                    <Upload
                      name="avatar"
                      accept="image/*"
                      className={styles.acc_profile_box}
                      showUploadList={false}
                      customRequest={(data) => customRequest(data, "picture")}
                    >
                      <Button icon={<UploadOutlined />}>
                        Click to upload thumbnail image
                      </Button>
                    </Upload>
                  </ImgCrop>
                </div>
                <div className={styles.imagesDivider}>
                  Or Select from Our Stock Images
                </div>

                <div className={styles.stockImageHorizontalList}>
                  {stockImageUrls.map((imagePath, itemIndex) => (
                    <div
                      key={itemIndex}
                      className={styles.preloadedImageWrapper}
                    >
                      <img
                        key={imagePath}
                        src={imagePath}
                        alt="Preloaded"
                        onClick={() => setSelectedImage(imagePath)}
                        className={
                          selectedImage === imagePath
                            ? styles.preloadedImageSelected
                            : styles.preloadedImage
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
            </React.Fragment>
          ) : null}

          <Col
            style={{
              display: "inline-block",
              width: "97%",
              margin: "0 8px",
            }}
          >
            <p className={styles.formLabel}>
              Description <span>*</span>
            </p>
            <Form.Item
              name="description"
              rules={[
                {
                  required: true,
                  message: "Description is required",
                },
              ]}
            >
              <Input.TextArea
                maxLength={1500}
                showCount={true}
                rows={12}
                placeholder="Please provide content Description"
              />
            </Form.Item>
          </Col>

          <Row>
            <p className={styles.formLabel}>
              Wellness practices (select up to 5 wellness practices that apply
              to your work from the list below) <span>*</span>
            </p>
            <Col
              style={{
                display: "inline-block",
                width: "45%",
                margin: "0 8px",
              }}
            >
              <Form.Item name="wellnesskeywords">
                <Tree
                  checkable
                  showLine
                  style={{ width: 300 }}
                  checkedKeys={selectedWellnessKeyword}
                  onCheck={(val) => setSelectedWellnessKeyword(val)}
                  treeData={
                    categories.length
                      ? categories.map((i) => ({
                          key: i.name,
                          title: i.name,
                          checkable: false,
                          children: i?.wellnessKeywords?.length
                            ? i?.wellnessKeywords?.map((j) => ({
                                key: j.id,
                                title: j.name,
                                selectable: true,
                              }))
                            : [],
                        }))
                      : []
                  }
                />
              </Form.Item>
            </Col>
            <Col
              style={{
                display: "inline-block",
                width: "45%",
                margin: "0 8px",
              }}
            >
              {selectedWellnessKeyword?.length > 0 ? (
                <div>
                  <h3>Selected Wellness Keywords (Max 5)</h3>
                  {selectedWellnessKeyword?.length
                    ? selectedWellnessKeyword?.map((i) => (
                        <p key={i}>
                          {wellnessKeywords?.find((j) => j.id === i)?.name}{" "}
                          &nbsp;{" "}
                          <CloseOutlined
                            onClick={(e) => {
                              e.preventDefault();
                              setSelectedWellnessKeyword(
                                selectedWellnessKeyword.filter((p) => p !== i)
                              );
                            }}
                            style={{ color: "red", cursor: "pointer" }}
                          />
                        </p>
                      ))
                    : null}
                </div>
              ) : (
                <p>No Wellness Keywords Selected</p>
              )}
            </Col>
          </Row>

          <Col
            style={{
              display: "inline-block",
              width: "97%",
              margin: "0 8px",
            }}
          >
            <p className={styles.formLabel}>
              Visibility <span>*</span>
            </p>
            <Form.Item
              name="privacy"
              rules={[
                {
                  required: true,
                  message: "Visibility is required",
                },
              ]}
            >
              <Select
                placeholder="Select a option and change input text above"
                allowClear
              >
                <Option value="public">Public</Option>
                <Option value="private">Private</Option>
              </Select>
            </Form.Item>
          </Col>

          <Row>
            <Col span={17}>
              <button
                className={styles.formSubmitButton}
                onClick={() => {
                  form
                    .validateFields()
                    .then((value) => {
                      console.log("value", value);
                      onFinish(value);
                    })
                    .catch((error) => {
                      console.log("error", error);
                    });
                }}
              >
                {editData ? "Update" : "Submit"}
              </button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default MemberContentAddEditModal;
