import { useEffect, useState } from "react";
import { getReviewsAPI } from "../../api/reviewsApis";
import { useMemberStore } from "../../store/memberStore";
import styles from "./MemberReviews.module.css";
import { Progress } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import MemberReviewAddEditModal from "../MemberReviewAddEditModal";
import { EditFilled } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { useRestrictionStore } from "../../store/restrictions";

const MemberReviews = () => {
  let { memberUserId } = useParams();

  const restrictionsData = useRestrictionStore(
    (state) => state.restrictionsData
  );

  const memberAccountInfo = useMemberStore((state) => state.memberAccountInfo);
  const { isAuthenticated, user, loginWithRedirect } = useAuth0();
  const [reviewData, setReviewData] = useState(null);
  const [addEditReviewModal, setAddEditReviewModal] = useState(false);
  const [selectedReview, setSelectedReview] = useState();

  const getReviewsHelper = async () => {
    try {
      const data = await getReviewsAPI({
        type: "member",
        typeId: memberAccountInfo?.id,
      });
      setReviewData(data?.data);
    } catch (error) {
      console.log("🚀 ~ getReviewsHelper ~ error:", error);
    }
  };

  useEffect(() => {
    if (memberAccountInfo?.id) {
      getReviewsHelper();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberAccountInfo]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.headingSectionWrapper}>
        <div className={styles.heading}>Reviews</div>

        {isAuthenticated &&
        memberAccountInfo.username === memberUserId &&
        memberAccountInfo?.user_authId === user?.sub ? null : (
          <>
            {Number(
              restrictionsData?.find(
                (i) =>
                  i.restrictionName === "Add-Reviews" && i.status === "active"
              )?.value
            ) && memberAccountInfo?.role !== "basic" ? (
              <div
                className={styles.writeAReview}
                onClick={() =>
                  isAuthenticated &&
                  memberAccountInfo?.user_authId !== user?.sub
                    ? setAddEditReviewModal(true)
                    : loginWithRedirect({
                        appState: {
                          returnTo: window.location.pathname,
                        },
                      })
                }
              >
                Add Review
              </div>
            ) : null}
          </>
        )}
      </div>

      {reviewData ? (
        <>
          <div className={styles.reviewProgressWrapper}>
            Effectiveness{" "}
            <Progress
              percent={(Number(reviewData?.averageQ1) / 10) * 100}
              format={() =>
                parseFloat(Number(reviewData?.averageQ1).toFixed(1))
              }
            />{" "}
          </div>
          <div className={styles.reviewProgressWrapper}>
            Supportive{" "}
            <Progress
              percent={(Number(reviewData?.averageQ2) / 10) * 100}
              format={() =>
                parseFloat(Number(reviewData?.averageQ2).toFixed(1))
              }
            />{" "}
          </div>
          <div className={styles.reviewProgressWrapper}>
            personalized{" "}
            <Progress
              percent={(Number(reviewData?.averageQ3) / 10) * 100}
              format={() =>
                parseFloat(Number(reviewData?.averageQ3).toFixed(1))
              }
            />{" "}
          </div>

          {/* reviews */}

          {reviewData?.rows?.length ? (
            <div className={styles.writtenReviewsWrapper}>
              <div className={styles.reviewsWrapper}>
                {reviewData?.rows?.map((review) => (
                  <div className={styles.reviewWrapper} key={review?.id}>
                    <p className={styles.reviewText}>{review?.reviewText}</p>
                    <div className={styles.reviewUserDetailsWrapper}>
                      <div>
                        <img
                          className={styles.reviewUserDetailsImage}
                          src={review?.UserSetting?.image}
                          alt="user profile"
                        />{" "}
                        <span className={styles.reviewUserDetailsName}>
                          {review?.UserSetting?.firstName}{" "}
                          {review?.UserSetting?.lastName}
                        </span>
                      </div>
                      {review?.user_authId === user?.sub ? (
                        <div
                          className={styles.reviewListItemEditButton}
                          onClick={() => {
                            setAddEditReviewModal(true);
                            setSelectedReview(review);
                          }}
                        >
                          <EditFilled />
                        </div>
                      ) : null}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : null}

          {addEditReviewModal ? (
            <MemberReviewAddEditModal
              editData={selectedReview}
              isOpen={addEditReviewModal}
              onClose={() => {
                setAddEditReviewModal(false);
                setSelectedReview();
                getReviewsHelper();
              }}
            />
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default MemberReviews;
