import { Button, Calendar, Empty, Modal, Spin } from "antd";
import { useEffect, useState, useCallback } from "react";
import styles from "./AppointmentUpdateModal.module.css";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";
import duration from "dayjs/plugin/duration";
import advancedFormat from "dayjs/plugin/advancedFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import timezone from "dayjs/plugin/timezone";
import { convertUTCToLocalTime, getDuration } from "../../utils";
import { getBusyTimeByMemberAppointmentAPI } from "../../api/appointmentApis";
import { getManualTimeScheduleAPI } from "../../api/manualTimeSchedule";
import { updateGoogleCalendarCredentialDB } from "../../api/googleCalenderCredentials";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { getMemberDetailsWithUserAuthIdAPI } from "../../api/memberApis";

dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(duration);
dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);
dayjs.extend(timezone);

const AppointmentUpdateModal = ({
  isOpen,
  onClose,
  appointmentData,
  onUpdate,
}) => {
  const { getIdTokenClaims } = useAuth0();

  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState();
  const [loader, setLoader] = useState(false);
  const [manualTimeSchedule, setManualTimeSchedule] = useState(false);
  const [memberAccountInfo, setMemberAccountInfo] = useState();

  const getMemberAccountInfo = async (authId) => {
    try {
      const response = await getMemberDetailsWithUserAuthIdAPI(authId);

      setMemberAccountInfo(response.data.data);
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  const getNewAccessTokenForGoogle = async () => {
    try {
      setLoader(true);
      const response = await axios.post(
        "https://oauth2.googleapis.com/token",
        new URLSearchParams({
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
          grant_type: "refresh_token",
          refresh_token: memberAccountInfo?.googleCredentials?.refresh_token,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (response.status === 200) {
        // const data = response.data;
        // const createdAt = Math.floor(Date.now() / 1000);

        const token = await getIdTokenClaims();

        await updateGoogleCalendarCredentialDB({
          access_token: response.data.access_token,
          user_authId: memberAccountInfo?.user_authId,
          googleCredentialId: memberAccountInfo?.googleCredentials?.id,
          token: token.__raw,
        });

        await getMemberAccountInfo();
      } else {
        console.error(`Error: ${JSON.stringify(response.data)}`);
        // disconnectGoogleCalendar(userAuthId, true);
      }
      setLoader(false);
    } catch (error) {
      console.error(`Error: ${error}`);
      // disconnectGoogleCalendar(userAuthId, true);
      setLoader(false);
    }
  };

  const fetchBusySlots = async ({ startTime, endTime }) => {
    setLoader(true);
    const timeMin = new Date(startTime).toISOString();
    const timeMax = new Date(endTime).toISOString();

    if (!memberAccountInfo?.googleCredentials?.access_token) {
      console.log("Access token is null");
      return;
    }

    try {
      const response = await fetch(
        "https://www.googleapis.com/calendar/v3/freeBusy",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${memberAccountInfo?.googleCredentials?.access_token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            timeMin: timeMin,
            timeMax: timeMax,
            items: [{ id: "primary" }],
          }),
        }
      );

      const data = await response.json();

      if (response.status === 200) {
        const busyData = data.calendars.primary.busy;
        console.log("🚀 ~ fetchBusySlots ~ busyData:", busyData);
        if (busyData?.length) {
          generateTimeSlots(busyData);
        }
      } else {
        console.log(`Error fetching busy slots: ${response.status}`);
        getNewAccessTokenForGoogle();
      }
      setLoader(false);
    } catch (error) {
      console.log(`Error fetching busy slots: ${error}`);
      setLoader(false);
    }
  };

  // Generate an array of time slots
  const generateTimeSlots = async (busySlots = []) => {
    setLoader(true);
    // Get current date and time
    const now = dayjs();
    const selectedDay = dayjs(selectedDate);

    // Determine start and end date times
    let startDateTime;
    let endDateTime = selectedDay.endOf("day");

    if (selectedDay.isSame(now, "day")) {
      startDateTime = now.startOf("hour").add(1, "hour"); // Start from the next hour
    } else {
      startDateTime = selectedDay.startOf("day");
    }

    // Calculate start hour for the time slots
    let startHour = startDateTime.hour();
    let endHour = 24;

    const isTokenExpired = () => {
      const currentTime = new Date();
      const tokenExpirationTime = new Date(
        memberAccountInfo?.googleCredentials?.createdAt * 1000 +
          memberAccountInfo?.googleCredentials?.expiresIn * 1000
      );

      if (currentTime > tokenExpirationTime) {
        return true;
      } else {
        return false;
      }
    };

    if (
      memberAccountInfo?.googleCredentials?.access_token &&
      !busySlots.length
    ) {
      if (isTokenExpired()) {
        console.log("Token expired");
        const refreshToken =
          memberAccountInfo?.googleCredentials?.refresh_token;
        if (refreshToken && refreshToken.length > 0) {
          // refreshGoogleAccessToken(userAuthId);
          // need to call api to fetch new access token
          getNewAccessTokenForGoogle();
        } else {
          console.log("No refresh token");
        }
      } else {
        console.log("Token not expired");
        fetchBusySlots({
          endTime: endDateTime,
          startTime: selectedDay.isSame(now, "day")
            ? now.startOf("hour").add(1, "hour")
            : selectedDay.startOf("day"),
        });
      }
    } else if (!busySlots.length) {
      const manualTimeScheduleData = await getManualTimeScheduleAPI(
        memberAccountInfo.user_authId
      );

      if (manualTimeScheduleData?.data?.startTime) {
        startHour = selectedDay.isSame(now, "day")
          ? now.startOf("hour").add(1, "hour")
          : dayjs(manualTimeScheduleData?.data?.startTime).local().hour();
        endHour = dayjs(manualTimeScheduleData?.data?.endTime).local().hour();

        setManualTimeSchedule(manualTimeScheduleData?.data);
      }
    }

    // Generate an array of time slots with AM/PM format
    const slots = Array.from({ length: endHour - startHour }, (_, index) => {
      const hour = (startHour + index) % 24; // Generate hour in 24-hour format
      const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
      const period = hour < 12 || hour === 24 ? "AM" : "PM";
      return {
        time: `${formattedHour}:00 ${period}`,
        hour: hour,
      };
    });

    const BusyTimeByMemberAppointment = await getBusyTimeByMemberAppointmentAPI(
      memberAccountInfo.user_authId,
      dayjs(selectedDate).format("YYYY-MM-DD")
    );

    if (busySlots.length || BusyTimeByMemberAppointment.data.length) {
      // Convert busy slots to hours
      const busyHours = busySlots.length
        ? busySlots.map((slot) => {
            const startLocalTime = convertUTCToLocalTime(slot.start);
            const endLocalTime = convertUTCToLocalTime(slot.end);
            return {
              startHour: startLocalTime.hour,
              startMinute: startLocalTime.minute,
              endHour: endLocalTime.hour,
              endMinute: endLocalTime.minute,
            };
          })
        : [];

      let memberBusyHours = [];

      if (BusyTimeByMemberAppointment.data.length) {
        memberBusyHours = BusyTimeByMemberAppointment.data.map((slot) => {
          const startLocalTime = convertUTCToLocalTime(slot.startdatetime);
          const endLocalTime = convertUTCToLocalTime(slot.enddatetime);
          return {
            startHour: startLocalTime.hour,
            startMinute: startLocalTime.minute,
            endHour: endLocalTime.hour,
            endMinute: endLocalTime.minute,
          };
        });
      }
      // Filter out the slots that overlap with busy slots
      const availableSlots = slots.filter((slot) => {
        return ![...busyHours, ...memberBusyHours].some((busySlot) => {
          const slotStart = slot.hour;
          const slotEnd = slot.hour + 1;
          const busyStart = busySlot.startHour + busySlot.startMinute / 60;
          const busyEnd = busySlot.endHour + busySlot.endMinute / 60;

          // Exclude slot if any part of the hour is occupied
          return (
            (slotStart < busyEnd && slotEnd > busyStart) ||
            (slotStart >= busyStart && slotStart < busyEnd)
          );
        });
      });

      // Format the available slots to 12-hour format with AM/PM
      setTimeSlots(availableSlots.map((slot) => slot.time));
    } else {
      setTimeSlots(slots.map((slot) => slot.time));
    }
    setLoader(false);
  };

  useEffect(() => {
    getMemberAccountInfo(appointmentData?.memberAuthId);
  }, [appointmentData]);

  useEffect(() => {
    if (memberAccountInfo) {
      generateTimeSlots();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, memberAccountInfo]);

  const onPanelChange = (newDate) => {
    if (newDate.isBefore(dayjs().startOf("month"))) {
      setSelectedDate(dayjs()); // Reset to the current month
      setSelectedTimeSlot();
    } else {
      setSelectedDate(newDate);
      setSelectedTimeSlot();
    }
  };
  const disabledDate = useCallback(
    (current) => {
      // Disable dates before today
      if (manualTimeSchedule?.startTime) {
        const dayAvailability = {
          sunday: manualTimeSchedule?.sunday,
          monday: manualTimeSchedule?.monday,
          tuesday: manualTimeSchedule?.tuesday,
          wednesday: manualTimeSchedule?.wednesday,
          thursday: manualTimeSchedule?.thursday,
          friday: manualTimeSchedule?.friday,
          saturday: manualTimeSchedule?.saturday,
        };

        // Get the day of the week (0-6) where 0 = Sunday, 1 = Monday, etc.
        const dayOfWeek = current.day();

        // Mapping dayOfWeek to the dayAvailability object
        const dayMap = {
          0: "sunday",
          1: "monday",
          2: "tuesday",
          3: "wednesday",
          4: "thursday",
          5: "friday",
          6: "saturday",
        };

        // Disable the date if it's a previous date or if the corresponding day in dayAvailability is false
        return (
          current < dayjs().startOf("day") ||
          !dayAvailability[dayMap[dayOfWeek]]
        );
      } else {
        return current && current < dayjs().startOf("day");
      }
    },
    [manualTimeSchedule]
  );
  const onSelect = (date) => {
    setSelectedDate(date);
    setSelectedTimeSlot();
  };

  return (
    <Modal
      title={"Update Appointment"}
      open={isOpen}
      onCancel={onClose}
      destroyOnClose={true}
      footer={null}
      width={800}
    >
      <Spin spinning={loader}>
        <>
          <div className={styles.calenderWrapper}>
            <Calendar
              value={selectedDate}
              disabledDate={disabledDate}
              fullscreen={false}
              onPanelChange={onPanelChange}
              onSelect={onSelect}
            />
          </div>
          {timeSlots?.length ? (
            <div className={styles.timeSlotWrapper}>
              {timeSlots.map((i) => (
                <div
                  className={
                    i === selectedTimeSlot
                      ? styles.selectedTimeSlotItem
                      : styles.timeSlotItem
                  }
                  onClick={() => setSelectedTimeSlot(i)}
                >
                  {i}
                </div>
              ))}
            </div>
          ) : (
            <Empty />
          )}
          <Button
            disabled={!selectedDate || !selectedTimeSlot}
            style={{ marginTop: "24px" }}
            type="primary"
            block
            onClick={() => {
              const parsedDate = dayjs(selectedDate);
              const parsedTime = dayjs(selectedTimeSlot, "h:mm A");

              const combinedDateTime = parsedDate
                .hour(parsedTime.hour())
                .minute(parsedTime.minute())
                .second(parsedTime.second());

              const durationParts = getDuration(
                appointmentData?.startdatetime,
                appointmentData?.enddatetime
              ).split(":");
              const hours = parseInt(durationParts[0], 10);
              const minutes = parseInt(durationParts[1], 10);
              const durationToAdd = dayjs.duration({ hours, minutes });

              // Add the duration to the combined datetime
              const endDateTime = combinedDateTime.add(durationToAdd);

              onUpdate(combinedDateTime.utc(), endDateTime.utc());
            }}
          >
            Confirm
          </Button>
        </>
      </Spin>
    </Modal>
  );
};

export default AppointmentUpdateModal;
