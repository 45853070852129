import { useAuth0 } from "@auth0/auth0-react";
import { Result } from "antd";
import { useCallback, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getMemberDetailsWithUserAuthIdAPI } from "../../api/memberApis";
import { getUserDataAPI } from "../../api/userApis";

const Auth0Callback = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const subscription = searchParams.get("subscription");
  const {
    user,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getIdTokenClaims,
  } = useAuth0();

  const ifAuthenticatedHandler = useCallback(async () => {
    if (
      user[`${process.env.REACT_APP_AUTH0_DOMAIN}/roles`]?.find(
        (i) => i === "Member"
      )
    ) {
      const userDataFromAPI = await getMemberDetailsWithUserAuthIdAPI(user.sub);

      if (subscription === "premium") {
        navigate(
          `/member/${userDataFromAPI?.data?.data?.username}?subscription=premium`
        );
      } else {
        navigate(`/member/${userDataFromAPI?.data?.data?.username}`);
      }
    } else {
      // user.sub
      try {
        const token = await getIdTokenClaims();
        const response = await getUserDataAPI(user.email, token?.__raw);
        if (
          response?.data?.data?.firstName &&
          response?.data?.data?.firstName?.length &&
          response?.data?.data?.lastName &&
          response?.data?.data?.lastName?.length &&
          response?.data?.data?.image &&
          response?.data?.data?.image?.length
        ) {
          if (subscription === "premium") {
            navigate(`/become-a-member?subscription=premium`);
          } else if (subscription === "basic") {
            navigate(`/become-a-member?subscription=basic`);
          } else {
            navigate(`/become-a-member`);
          }
        } else {
          if (subscription === "premium") {
            navigate("/user-register?subscription=premium");
          } else if (subscription === "basic") {
            navigate("/user-register?subscription=basic");
          } else {
            navigate("/user-register");
          }
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  }, [navigate, user, getIdTokenClaims, subscription]);

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        ifAuthenticatedHandler();
      } else {
        loginWithRedirect();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading, user]);

  return (
    <div>
      <Result title="Please wait, we are processing your request" />
    </div>
  );
};

export default Auth0Callback;
